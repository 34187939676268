import React, { Suspense, lazy,useEffect,useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "./App.scss";
import Layout from "./components/layout/Layout";
import { RtlProvider } from "./RtlContext";
import { SnackbarProvider } from "./SnackbarContext";
import Routing from "./routing/Routings";
import { AuthProvider, useAuth } from "./components/auth/AuthProvider";
import { useLocation,useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import {ResetPasswordLinkConsumed} from "./services/ExpirelinkService";
import { AccountProvider } from "./components/account/AccountContext";
import Cookies from "js-cookie";
import { User } from "./models/User";
import jwtDecode from "jwt-decode";

function App() {
	const location = useLocation();
	//const passwordLogId = useParams();
	const navigate = useNavigate();
	const { signOut, loggedOut } = useAuth();
	//const history = useHistory();
	const [isResetLinkValid, setIsResetLinkValid] = useState(false);
	const [isCheckingLink, setIsCheckingLink] = useState(false);
	//const noLayoutRoutes = ["/login", "/forgotpassword", "/resetpassword/passwordLogId", "/admin/adminlogin"];
	const [loginUserDetails, setLoginUserDetail] = useState<User | null>();
	const noLayoutRoutes = ["/login", "/forgotpassword", "/resetpassword/:passwordLogId", "/admin/adminlogin", "/linkexpired"];
	const uuidRegex = "[0-9a-fA-F-]{36}";
	const isNoLayoutRoute = noLayoutRoutes.some(route => {
		if (route === "/resetpassword/:passwordLogId") {
			const resetPasswordRegex = new RegExp(`^/resetpassword/${uuidRegex}$`);
			return resetPasswordRegex.test(location.pathname);
		}
		//console.log(location.pathname === route)
		return location.pathname === route;
	});
	const token = localStorage.getItem("access_token");
	if (token) {					
		const decodedToken: any = jwtDecode(token);
		const currentTime = Math.floor(Date.now() / 1000);
		if (decodedToken.exp < currentTime) {
			localStorage.clear();
			navigate("/login")
		}
	}
	const extractPasswordLogId = () => {
		const match = location.pathname.match(new RegExp(`/resetpassword/(${uuidRegex})`));
		return match ? match[1] : null;
	};

	const passwordLogId = extractPasswordLogId();
	const checkResetLinkConsumed = async (passwordLogId: any) => {
		try {
			setIsCheckingLink(true);
			const response = await ResetPasswordLinkConsumed(passwordLogId);
			
			if (response === true) {
				const isConsumed = await response
				setIsResetLinkValid(!isConsumed);
				if (isConsumed === true) {
					navigate("/linkexpired");
				}
			} else {
				navigate(`/resetpassword/${passwordLogId}`)
			}
		} catch (error) {
			//console.log('this is app.tsx and got error', error)
			navigate("/login")
		} finally {
			setIsCheckingLink(false);
		}
	};
	
	
	
	useEffect(() => {
		if (location.pathname.includes("/resetpassword") && passwordLogId) {
			checkResetLinkConsumed(passwordLogId);
		}
	}, [passwordLogId]);

	if (isCheckingLink) {
		return <div>Loading...</div>;
	}

	return (
		<>						<SnackbarProvider>

			{isNoLayoutRoute ? (
				<Routing />
			) : (
				<AuthProvider>
					<AccountProvider>
							<Suspense fallback={<div>Loading...</div>}>
								<RtlProvider>
									<Layout>
										<Routing />
									</Layout>
								</RtlProvider>
							</Suspense>
					</AccountProvider>
				</AuthProvider>
			)}
									</SnackbarProvider>

		</>
	);
	
}

export default App;
