import React, { useState } from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogActions,
  Button, TextField, IconButton, InputAdornment, Typography, Box,
  FormHelperText,
  Snackbar,
  Alert
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import CloseIcon from '@mui/icons-material/Close';
import { ChangePassword } from '../../services/ProfileSettingPageService';
// Assuming you have encryption logic in utils
import { JSEncrypt } from "jsencrypt";
import { useAuth } from '../auth/AuthProvider';
import { useTranslation } from 'react-i18next';
interface ChangePasswordPopupProps {
  open: boolean;
  onClose: () => void;
}

const publicKey = process.env.REACT_APP_PUBLIC_ENCRYPT_KEY; 
const PasswordFieldLabel: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <Typography
    sx={{
      fontFamily: 'Poppins',
      fontSize: '14px',
      fontWeight: '500',
      color: 'rgba(34, 34, 34, 1)',
      marginBottom: '-14px',
      lineHeight: '20px'
    }}
  >
    {children}
  </Typography>
);

const ChangePasswordPopup: React.FC<ChangePasswordPopupProps> = ({ open, onClose }) => {
  const { t: translate } = useTranslation();
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [passwordErrors, setPasswordErrors] = useState<string[]>([]);
  const [currentPasswordError, setCurrentPasswordError] = useState<string>('');
  const { userDetails } = useAuth();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">("success");
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
    onClose(); // Close the dialog when Snackbar closes
  };

  // Updated handleChangePassword method to use encryption
  const handleChangePassword = async () => {
    try {
      const encryptedCurrentPassword = encryptData(currentPassword);
      const encryptedNewPassword = encryptData(newPassword);
  
      const response = await ChangePassword(userDetails?.UserId, encryptedCurrentPassword, encryptedNewPassword);
  
      if (response.Success) {
        setSnackbarMessage("Password Updated Successfully");
        setSnackbarSeverity("success");
      } else {
        setSnackbarMessage('Failed to change password: ' + response.ErrorMessage);
        setSnackbarSeverity("error");
      }
  
      // Open the Snackbar
      setSnackbarOpen(true);
  
      // Close the Snackbar and Dialog after 2 seconds
      setTimeout(() => {
        setSnackbarOpen(false);
        handleClose(); // Close the dialog
      }, 2000);
  
    } catch (error) {
      setSnackbarMessage('Error changing password');
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
  
      // Close the Snackbar and Dialog after 2 seconds
      setTimeout(() => {
        setSnackbarOpen(false);
        onClose(); // Close the dialog
      }, 2000);
    }
  };
  const handleClose = () => {
    resetForm(); // Reset form fields when dialog closes
    handleSnackbarClose();
  };

  // Reset form fields
  const resetForm = () => {
    setCurrentPassword('');
    setNewPassword('');
    setConfirmNewPassword('');
    setPasswordErrors([]);
    setCurrentPasswordError('');
  };

  const encryptData = (password: string) => {
    const encrypt = new JSEncrypt();
    encrypt.setPublicKey(publicKey?publicKey:"");
    const encryptedPassword = encrypt.encrypt(password);
    return encryptedPassword;
  };
  const validatePassword = (password: string) => {
    const errors: string[] = [];
    const uppercaseRegex = /[A-Z]/;
    const lowercaseRegex = /[a-z]/;
    const numberRegex = /\d/;
    const spaceRegex = /\s/;

    if (password.length < 8) {
      errors.push('Password must be at least 8 characters long.');
    }
    if (password.length > 64) {
      errors.push('Password cannot exceed 64 characters.');
    }
    if (!uppercaseRegex.test(password)) {
      errors.push('Password must contain at least one uppercase letter.');
    }
    if (!lowercaseRegex.test(password)) {
      errors.push('Password must contain at least one lowercase letter.');
    }
    if (!numberRegex.test(password)) {
      errors.push('Password must contain at least one number.');
    }
    if (spaceRegex.test(password)) {
      errors.push('Password cannot contain spaces.');
    }
    return errors;
  };

  const validateCurrentPassword = (currentPassword: string, newPassword: string) => {
    // Check only if both fields have values
    if (currentPassword && newPassword && currentPassword === newPassword) {
      setCurrentPasswordError('Current password should not be the same as new password');
    } else {
      setCurrentPasswordError(''); // Clear the error if it's valid
    }
  };

  const handleCurrentPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const currentPass = event.target.value;
    setCurrentPassword(currentPass);
    // Call the validation only if the new password is filled
    if (newPassword) {
      validateCurrentPassword(currentPass, newPassword);
    }
  };
  
  const handleNewPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newPass = event.target.value;
    setNewPassword(newPass);
    const errors = validatePassword(newPass);
    setPasswordErrors(errors);
    // Validate the current password only if it's filled
    if (currentPassword) {
      validateCurrentPassword(currentPassword, newPass);
    }
  };

  const handleConfirmNewPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmNewPassword(event.target.value);
  };
  const isFormValid = () => {
    return (
      currentPassword.trim() !== '' &&
      newPassword.trim() !== '' &&
      confirmNewPassword.trim() !== '' &&
      newPassword !==currentPassword &&
      newPassword === confirmNewPassword &&
      passwordErrors.length === 0
    );
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: {
          width: '463px',
          height: 'auto',
          borderRadius: '8px',
          position: 'relative',
          padding: '20px',
        },
      }}
    >
      <DialogTitle sx={{ padding: '0px', marginBottom: '10px' }}>
        <Typography
          sx={{
            fontFamily: 'Poppins',
            fontSize: '20px',
            fontWeight: '500',
            lineHeight: '30px',
            color: 'rgba(34, 34, 34, 1)',
          }}
        >
         <b> {translate('Change Password')}</b>
        </Typography>
        <IconButton
          className='qadpt-crossIcon'
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ padding: '0px', marginBottom: '10px' }}>
        <PasswordFieldLabel><b>{translate('Enter current password')}</b></PasswordFieldLabel>
        <TextField
          placeholder={translate('Minimum 8 characters')}
          type={showCurrentPassword ? 'text' : 'password'}
          value={currentPassword}
          onChange={handleCurrentPasswordChange}
          fullWidth
          variant="outlined"
          margin="normal"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                >
                  {/* {showCurrentPassword ? <VisibilityOff /> : <Visibility />} */}
                  <i className={`fal ${showCurrentPassword ? "fa-eye-slash" : "fa-eye"}`}></i>

                </IconButton>
              </InputAdornment>
            ),
            sx: {
              height: '47px',
              borderRadius: '6px',
            },
          }}
          sx={{ marginBottom: '15px' }}
        />
        
        <PasswordFieldLabel><b>{translate('Enter new password')}</b></PasswordFieldLabel>
        <TextField
          placeholder={translate('Minimum 8 characters')}
          type={showNewPassword ? 'text' : 'password'}
          value={newPassword}
          onChange={handleNewPasswordChange}
          fullWidth
          variant="outlined"
          margin="normal"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowNewPassword(!showNewPassword)}
                >
                  {/* {showNewPassword ? <VisibilityOff /> : <Visibility />} */}
                  <i className={`fal ${showNewPassword ? "fa-eye-slash" : "fa-eye"}`}></i>

                </IconButton>
              </InputAdornment>
            ),
            sx: {
              height: '47px',
              borderRadius: '6px',
            },
          }}
          sx={{ marginBottom: '15px' }}
          helperText={
            <FormHelperText sx={{ color: 'red', fontSize: '12px' }}>
              {currentPasswordError}
              {passwordErrors.map((error, index) => (
                <Typography key={index} sx={{ color: 'red', fontSize: '12px' }}>
                  {error}
                </Typography>
              ))}
            </FormHelperText>
          }
        />
        
        <PasswordFieldLabel><b>{translate('Re enter new password')}</b></PasswordFieldLabel>
        <TextField
          placeholder={translate('Re-enter new password')}
          type={showConfirmNewPassword ? 'text' : 'password'}
          value={confirmNewPassword}
          onChange={handleConfirmNewPasswordChange}
          fullWidth
          variant="outlined"
          margin="normal"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowConfirmNewPassword(!showConfirmNewPassword)}
                >
                  {/* {showConfirmNewPassword ? <VisibilityOff /> : <Visibility />} */}
                  <i className={`fal ${showConfirmNewPassword ? "fa-eye-slash" : "fa-eye"}`}></i>

                </IconButton>
              </InputAdornment>
            ),
            sx: {
              height: '47px',
              borderRadius: '6px',
            },
          }}
          helperText={
            <FormHelperText sx={{ color: 'red', fontSize: '12px' }}>
              {confirmNewPassword && newPassword !== confirmNewPassword ? 'Passwords do not match' : ''}
            </FormHelperText>
          }
        />
      </DialogContent>
      <DialogActions sx={{ padding: '0px' }}>
        <Box sx={{ flexGrow: 1 }}></Box>
        <Button
          onClick={handleChangePassword}
          disabled={!isFormValid()}
          style={{color:'white'}}
          sx={{
            backgroundColor: isFormValid() ? 'rgba(95, 158, 160, 1)' : '#a5c3c5', // Enabled color vs Disabled color
            color: 'white',
            textTransform: 'none',
            borderRadius: '8px',
            width: '100px',
            height: '36px',
            '&:hover': {
              backgroundColor: 'rgba(95, 158, 160, 1)',
            }
          }}
        >
  
          {translate('Confirm')}
        </Button>
      </DialogActions>
      
<Snackbar
    open={snackbarOpen}
    autoHideDuration={6000}
    onClose={handleSnackbarClose}
    anchorOrigin={{ vertical: "top", horizontal: "center" }}
    sx={{ zIndex: 10000,marginTop:4}} // Optionally adjust the zIndex if needed
>
    <Alert
        onClose={handleSnackbarClose}
        severity={snackbarSeverity}
        sx={{ width: "100%" }}
    >
        {snackbarMessage}
    </Alert>
</Snackbar>

    </Dialog>
  );
};

export default ChangePasswordPopup;
