import { Button } from "@mui/material";
import { deleteUserRole } from "../../services/UserRoleService";
import { useTranslation } from "react-i18next";

const ConfirmationDialogDelete = (props:any) => {
    const {
        fetchUserRoles,
        roleToDelete,
        setRoleToDelete,
        confirmDialogOpen,
        setConfirmDialogOpen,
        userIdroletodelete,
        openSnackbar
    } = props;
    const handleDeleteRole = async () => {
        if (roleToDelete) {
            try {
                await deleteUserRole(roleToDelete, userIdroletodelete, openSnackbar);
                fetchUserRoles();
            } catch (error) {
                console.error('Failed to delete role', error);
            }
        }
        setConfirmDialogOpen(false);
        setRoleToDelete(null);
    };
    const { t: translate } = useTranslation();
    return (

        confirmDialogOpen && (
            <div className="qadpt-modal-overlay"> 
            <div className="qadpt-roledeletepopup">
                <div className="qadpt-title">{translate('Confirm Deletion')}</div>
                    <div className="qadpt-subtitle">{translate('Are you sure you want to delete this role?')}</div>
                <div className="qadpt-role-buttons">
                        <Button
                            // className="qadpt-cancel-button"
                            onClick={() => setConfirmDialogOpen(false)}>
                            {translate('Cancel')}
                    </Button>
                        <Button
                            className="qadpt-conform-button"
                            onClick={handleDeleteRole}>
                            {translate('Yes, Delete')}
                    </Button>
                </div>
            </div>
        </div>
        
        )
    );
}
export default ConfirmationDialogDelete
