  
  
import React, { useState, useTransition } from 'react';

import { isSidebarOpen } from "../adminMenu/sidemenustate";
import { Container } from '@mui/material';
import { useTranslation } from 'react-i18next';

const Audience = () => {
  const { t: translate } = useTranslation();
  const [sidebarOpen, setSidebarOpen] = useState(isSidebarOpen());
  return (
    <Container maxWidth="xl">
   
       <div className={`smooth-transition`} style={{marginLeft:sidebarOpen?"170px":""}}>
        <center><h1>{translate('Audience')} </h1></center>
      </div>
   
      </Container>
    );
  };
  

export default Audience