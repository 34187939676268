import React, { useState, useEffect, useTransition } from "react";
import {
	Dashboard,
	Audience,
	Tours,
	Announcements,
	Tooltips,
	Banners,
	Checklist,
	Surveys,
	Hotspots,
	Settings,
	Auditlog,
	Filemanagement
} from "../../assets/icons/icons";
import { useNavigate, useLocation } from "react-router-dom";
import { useTheme, ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import IconButton from "@mui/material/IconButton";
import Switch from "@mui/material/Switch";
import Box from "@mui/material/Box";
import MenuIcon from "@mui/icons-material/Menu";
import Typography from "@mui/material/Typography";
import Popup from "../common/Popup";
import ButtonBase from '@mui/material/ButtonBase';
import { isSidebarOpen, setSidebarOpen, subscribe } from "../adminMenu/sidemenustate";
import { useRtl } from "../../RtlContext";
import { Divider } from "@mui/material";
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useAuth } from "../auth/AuthProvider";
import { User } from "../../models/User";
import { useTranslation } from "react-i18next";

interface SideMenuProps {
	selectedLanguageProp: string;
}

const SideMenu: React.FC<SideMenuProps> = ({ selectedLanguageProp }) => {
	const { t: translate } = useTranslation();
	const [activeItem, setActiveItem] = useState<string>("");
	const [openPopup, setOpenPopup] = useState(false);
	const [sidebarOpen, setLocalSidebarOpen] = useState(isSidebarOpen());
	const [translatedTitles, setTranslatedTitles] = useState<{ [key: string]: string }>({});
	const { signOut, userDetails } = useAuth();
	const [OrganizationId, setOrganizationId] = useState(userDetails?.OrganizationId);
	const [user, setUser] = useState<User | null>(null);
	const navigate = useNavigate();
	const location = useLocation();

	const { isRtl, toggleRtl } = useRtl();

	const themeWithDirection = createTheme({
		direction: isRtl ? "rtl" : "ltr",
	});

	useEffect(() => {
		const userInfoString = localStorage.getItem("userInfo");	
		if (userInfoString) { 
			try {
				const userInfo = JSON.parse(userInfoString);	
				if (userInfo['user']) {
					const parsedUser = JSON.parse(userInfo['user']);
					setUser(parsedUser);	
					if (parsedUser) {
						const OrgId = parsedUser.OrganizationId ?? '';
						setOrganizationId(OrgId);
					}
				}
			} catch (error) {
				console.error("Error parsing userInfo: ", error);
			}
		}
		else if (userDetails) {
			setUser(userDetails);	
			if (userDetails) {
				const OrgId = userDetails.OrganizationId ?? '';
				setOrganizationId(OrgId);
			}
		}
	}, []);

	useEffect(() => {
		const unsubscribe = subscribe(setLocalSidebarOpen);
		return () => unsubscribe();
	}, []);

	const MenuClick = (key: string) => {
		if (key.toLowerCase() === "hotspots") {
			// Do nothing for disabled item
			return;
		}
		if (!OrganizationId)
		{
			const userInfoString = localStorage.getItem("userInfo");	
			if (userInfoString) { 
				try {
					const userInfo = JSON.parse(userInfoString);	
					if (userInfo['user']) {
						const parsedUser = JSON.parse(userInfo['user']);
						setUser(parsedUser);	
						if (parsedUser) {
							const OrgId = parsedUser.OrganizationId ?? '';
							setOrganizationId(OrgId);
						}
					}
				} catch (error) {
				console.error("Error parsing userInfo: ", error);
			}
		}
		}
		
		setActiveItem(key);
		switch (key.toLowerCase()) {
			case "dashboard":
				return navigate("/dashboard");
			case "audience":
				return navigate("/audience");
			case "tours":
				return navigate("/tours");
			case "announcements":
				return navigate("/announcements");
			case "tooltips":
				return navigate("/tooltips");
			case "banners":
				return navigate("/banners");
			case "checklists":
				return navigate("/checklists");
			case "surveys":
				return navigate("/surveys");
			case "hotspots":
				return navigate("/hotspots");
				case "settings":
					return navigate(`${OrganizationId}/team`);				
			// case "auditlogs":
			// 	return navigate("/auditlog");
			case 'filemanagement':
				return navigate("/filelist");
			default:
				return navigate("/");
		}
	};

	const toggleSidebar = () => {
		setLocalSidebarOpen(!sidebarOpen);
		setSidebarOpen(!sidebarOpen);
	};

	if (location.pathname === "/Builder") {
		return null;
	}

	const menuItems = [
		{ key: "Announcements", icon: Announcements, title: translate('Announcements') },
		{ key: "Banners", icon: Banners, title: translate('Banners') },
		{ key: "Settings", icon: Settings, title: translate('Settings') },
		{ key: "Dashboard", icon: Dashboard, title: translate('Dashboard'), disabled:true }, // Translate title
		{ key: "Audience", icon: Audience, title: translate('Audience'), disabled:true },
		{ key: "Tooltips", icon: Tooltips, title: translate('Tooltips'), disabled:true},
		{ key: "Tours", icon: Tours, title: translate('Tours'),disabled:true},
		{ key: "Checklists", icon: Checklist, title: translate('Checklists'), disabled:true},
		{ key: "Hotspots", icon: Hotspots, title: translate('Hotspots'), disabled: true },
		{ key: "Surveys", icon: Surveys, title: translate('Surveys'), disabled:true},
		{ key: "Filemanagement", icon: Filemanagement, title: translate('File Management'), disabled:true},
	  ];
	  

	return (
		<ThemeProvider theme={themeWithDirection}>
			<CssBaseline />
			<div className="qadpt-side-menu">
			<Box sx={{ display: "flex" }}>
				<Drawer
					variant="persistent"
					anchor={isRtl ? "right" : "left"}
					open={sidebarOpen}
					className="qadpt-smenu"
					sx={{
						"& .MuiDrawer-paper": {
							direction: isRtl ? "rtl" : "ltr",
						},
					}}
				>
					<PerfectScrollbar>
					<List className="qadpt-smenu-list">
						{menuItems.map(({key, icon, title, disabled }, index) => (
							<React.Fragment key={key}>
								<ButtonBase className={`qadpt-sm-item ${activeItem === key ? 'active' : 'inactive'} 
														${disabled ? 'disabled' : ''}`}
									onClick={() => MenuClick(key)}
									sx={{
										// marginLeft: '10px',
										// marginTop: '-3px',
										// width: "220px",
										// height: "48px",
										// padding: "12px 23px",
										// gap: "-5px",
										// borderRadius: "6px",
										//  backgroundColor: activeItem === title ? "rgba(95, 158, 160, 1)" : "transparent",
										// "&:hover": {
										// 	opacity: 1,
										// },
										// display: "flex",
										// alignItems: "center",
										// justifyContent: "flex-start",
										// cursor: disabled ? "not-allowed" : "pointer",
										// opacity: disabled ? 0.5 : 1,
										// whiteSpace: 'nowrap'
									}}
									disabled={disabled}
								>
									<ListItemIcon className={`qadpt-sm-icon ${activeItem === key ? 'active' : 'inactive'}`}
										// sx={{ minWidth: "40px", color: activeItem === title ? "#FFFFFF" : "#202224" }}
									>
										<img
											src={icon}
											alt={key}
											// style={{ filter: activeItem === title ? 'invert(1)' : 'none' }}
										/>
									</ListItemIcon>
									<Box sx={{textAlign:"left"}}>
										<Typography
											// sx={{
											// 	width: "81px",
											// 	height: "21px",
											// 	fontFamily: "Poppins, sans-serif",
											// 	fontWeight: 500,
											// 	fontSize: "13px",
											// 	lineHeight: "19px",
											// 	letterSpacing: "0.3px",
											// 	textAlign: "left",
											// 	color: activeItem === title ? "#FFFFFF" : "#202224",
											// 	ml: "-8px",
											// }}
										>
											{translatedTitles[title] || title}
										</Typography>
										{disabled && (
											<Typography className="cmg-soon"
											sx={{
												textAlign : "start", // Align to start for Filemanagement
												// fontFamily: "Poppins, sans-serif",
												// fontWeight: 400,
												// fontSize: "12px",
												// lineHeight: "18px",
												// color: "#b0b0b0",
											}}
											>
												Coming soon
											</Typography>
										)}
									</Box>
								</ButtonBase>
								{/* {index === 1 && <Divider sx={{ marginX: 2 }} />}
								{index === 9 && <Divider sx={{ marginX: 2 }} />} */}
							</React.Fragment>
						))}
						</List>		
					</PerfectScrollbar>	
					</Drawer>
					
			
				{/* <IconButton
					onClick={toggleSidebar}
					sx={{ ml: sidebarOpen ? (isRtl ? -30 : 30) : 1 }}
				>
					<MenuIcon />
				</IconButton> */}
				{openPopup && (
					<Popup
						onClose={() => setOpenPopup(false)}
						onOk={() => setOpenPopup(false)}
						type="Apply"
						title="Log out from QuickAdapt"
						description="Do you really want to logout?"
						button1="Cancel"
						button2="Logout"
					/>
				)}
				</Box>
				</div>
		</ThemeProvider>
	);
};


export default SideMenu;
