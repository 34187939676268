import React, { createContext, useContext, useEffect, useState, ReactNode } from 'react';
import { User, UserManager } from 'oidc-client-ts';
import userManager from './UseAuth';
import { LoginUserInfo } from '../../models/LoginUserInfo';
import jwt_decode from "jwt-decode";
import { User as LoginUser } from '../../models/User';
import { useNavigate, useLocation } from 'react-router-dom';
import { Content } from 'antd/es/layout/layout';
import { startTransition } from 'react';
interface AuthContextType {
  user: User | null;
  userDetails: LoginUser | null;
  signOut: () => void;
  loggedOut: boolean;

}
let userLocalData: { [key: string]: any } = {}
const AuthContext = createContext<AuthContextType | undefined>(undefined);

interface AuthProviderProps {
  children: ReactNode;
}
let initialsData: string;

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const location = useLocation();
  const [user, setUser] = useState<User | null>(null);
  const noLayoutRoutes = ["/login", "/forgotpassword", "/resetpassword/:passwordLogId", "/admin/adminlogin", "/linkexpired"];
	const uuidRegex = "[0-9a-fA-F-]{36}";
  const [userDetails, setUserDetails] = useState<LoginUser | null>(location.state?.userDetail || null);
  const [loggedOut, setLoggedOut] = useState<boolean>(false);

  const navigate = useNavigate();

  const calculateInitials = (firstName?: string, lastName?: string) => {
    const firstInitial = firstName ? firstName[0].toUpperCase() : '';
    const lastInitial = lastName ? lastName[0].toUpperCase() : '';
    return firstInitial + lastInitial;
  };
  // setUserDetails(location.state?.userDetails);
  
  useEffect(() => {
    const initializeUser = async () => {
      if (loggedOut) return;  // Skip reinitialization if user has logged out

      try {
        const userInfo = JSON.parse(localStorage.getItem("userInfo") || '{}');
        if (userInfo['oidc-info'] && userInfo['user']) {
          const user = JSON.parse(userInfo['user'])
          const oidcinfo = JSON.parse(userInfo['oidc-info'])
          setUser(user);
          if (oidcinfo.access_token) {
            const decodedToken = jwt_decode<LoginUserInfo>(oidcinfo.access_token);
            localStorage.setItem("userType", user.UserType);
            setUserDetails(user);
            const finalData = calculateInitials(
              user?.FirstName ? user?.FirstName.substring(0, 1).toUpperCase() : '',
              user?.LastName ? user?.LastName.substring(0, 1).toUpperCase() : ''
            );
            initialsData = finalData;          
          }
        }
        else {
          const isNoLayoutRoute = noLayoutRoutes.some(route => {
            if (route === "/resetpassword/:passwordLogId") {
              const resetPasswordRegex = new RegExp(`^/resetpassword/${uuidRegex}$`);
              return resetPasswordRegex.test(location.pathname);
            }
            return location.pathname === route;
          });
          if (!isNoLayoutRoute)
          {
            signOut();

            }
        }
      } catch (error) {
        console.error('Failed to fetch user details:', error);
        userManager.signoutRedirect();
      }
    };
  
    startTransition(() => {
      initializeUser();
    });
  },[loggedOut]);
  
  


 const signOut = () => {
   const logeduserType = localStorage.getItem('userType');
   setLoggedOut(true);
   startTransition(() => {
     setUser(null);
     setUserDetails(null);
     localStorage.clear();
     document.cookie.split(";").forEach(cookie => {
       const [name] = cookie.split("=");
       document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
     });
     localStorage.setItem('logout-event', Date.now().toString());
     if (logeduserType?.toLowerCase() !== "superadmin") {
       navigate("/login");
     } else {
       navigate("/admin/adminlogin");
     }
   });
};

  

  return (
    <AuthContext.Provider value={{ user, userDetails, signOut,loggedOut }}>
      {children}
    </AuthContext.Provider>
    );
    
};

export const useAuth = (): AuthContextType => {
  let context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  if (context?.user) {
    const userInfo = JSON.parse(localStorage.getItem("userInfo") || '{}');
    userLocalData["oidc-info"] = JSON.stringify(context.user);    

    if (userInfo['user']) {
      userLocalData["user"] =  JSON.stringify(userInfo['user'])
    }   
  } 
  else {
    const userInfo = JSON.parse(localStorage.getItem("userInfo") || '{}');
    if (userInfo['oidc-info'] && userInfo['user']) {
      context = { ...context, user: JSON.parse(userInfo['oidc-info']) };
      context.userDetails =  JSON.parse(userInfo['user'])
      context.loggedOut = false;
    }
  }

  return context;
};

export {initialsData}