import React, { useState, useEffect } from 'react';
import {
  Drawer,
  Box,
  Typography,
  CssBaseline,
  Button,
  TextField,
  IconButton,
  MenuItem,
  InputAdornment,
  Select,
  FormControl,
  Grid,
  Dialog,
  DialogContent,
  DialogActions
} from '@mui/material';
import { Search, ChevronLeft, ChevronRight, ArrowBack, CloudUpload } from '@mui/icons-material';
import { saveGuide } from '../../services/AccountService';

function getXPath(element: HTMLElement): string {
  const idx = (sib: Node | null, name: string): number => {
    if (!sib) return 0;
    return idx(sib.previousSibling, name) + (sib.nodeName === name ? 1 : 0);
  };

  const segs = (elm: HTMLElement): string[] => {
    const allNodes = document.getElementsByTagName('*');
    const segments: string[] = [];

    for (; elm && elm.nodeType === 1; elm = elm.parentNode as HTMLElement) {
      if (elm.hasAttribute('id')) {
        let uniqueIdCount = 0;
        for (let n = 0; n < allNodes.length; n++) {
          if (allNodes[n].hasAttribute('id') && allNodes[n].id === elm.id) uniqueIdCount++;
          if (uniqueIdCount > 1) break;
        }
        if (uniqueIdCount === 1) {
          segments.unshift(`id("${elm.id}")`);
          return segments;
        } else {
          segments.unshift(`${elm.localName.toLowerCase()}[@id="${elm.id}"]`);
        }
      } else if (elm.hasAttribute('class')) {
        segments.unshift(
          `${elm.localName.toLowerCase()}[@class="${elm.getAttribute('class')}"]`
        );
      } else {
        const i = idx(elm.previousSibling, elm.nodeName);
        segments.unshift(`${elm.localName.toLowerCase()}[${i}]`);
      }
    }
    return segments;
  };

  return segs(element).join('/');
}

export function CreateGuide() {
  const [open, setOpen] = useState(true);
  const [targeting, setTargeting] = useState('equals');
  const [showSecondScreen, setShowSecondScreen] = useState(false);
  const [guideName, setGuideName] = useState('');
  const [description, setDescription] = useState('');
  const [url, setUrl] = useState('');
  const [showTemplate, setShowTemplate] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [text, setText] = useState('');
  const [stepTitle, setStepTitle] = useState('');
  const [steps, setSteps] = useState<any[]>([]);
  const [selectedElementXPath, setSelectedElementXPath] = useState('');
  const [stepImage, setStepImage] = useState('');
  const [previousSelectedElement, setPreviousSelectedElement] = useState<HTMLElement | null>(null);
  const [guides, setGuides] = useState<any[]>([]);

  useEffect(() => {
    const savedGuides = JSON.parse(localStorage.getItem('guides') || '[]');
    setGuides(savedGuides);
  }, []);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const handleTargetingChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setTargeting(event.target.value as string);
  };

  const handleNextScreen = () => {
    setShowSecondScreen(true);
  };

  const handleBack = () => {
    setShowSecondScreen(false);
  };

  const handleAnnouncementClick = () => {
    setShowTemplate(true);
  };

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setFile(event.target.files[0]);
    }
  };

  const handleSaveStep = () => {
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64File = reader.result as string;
        setStepImage(base64File)
        const step = {
          title: stepTitle,
          text: text,
          image: base64File,
          xpath: selectedElementXPath
        };
        setSteps([...steps, step]);
        if (previousSelectedElement) {
          previousSelectedElement.style.outline = '';
        }
        handleDialogClose();
      };
      reader.readAsDataURL(file);
    } else {
      const step = {
        title: stepTitle,
        text: text,
        image: null,
        xpath: selectedElementXPath
      };
      setSteps([...steps, step]);
      if (previousSelectedElement) {
        previousSelectedElement.style.outline = '';
      }
      handleDialogClose();
    }
  };

const handleSaveGuide = async () => {
  const newGuide = {
    GuideId: '',
    Title: guideName,
    Content: description,
    OrganizationId: '01072024-102736432-72141ef9-3f20-4cc4-98e2-36ab9a54b79e',
    CreatedDate: new Date().toISOString(),
    UpdatedDate: new Date().toISOString(),
    CreatedBy: 'your-user-id',
    UpdatedBy: 'your-user-id',
    GuideStep: steps.map((step, index) => ({
      StepTitle: step.stepTitle || `Step ${index + 1}`,
      Text: step.text || text,
      Element: selectedElementXPath,
      On: step.on || 'right',
      Image: step.image,
      BackgroundColor: step.backgroundColor || '',
      Id: step.id || '',
      Arrow: step.arrow !== undefined ? step.arrow : true,
      Classes: step.classes || '',
      IsClickable: step.isClickable !== undefined ? step.isClickable : true,
    })),
  };

  try {
    const response = await saveGuide(newGuide);

    if (response.success) {
      const updatedGuides = [newGuide];
      setGuides(updatedGuides);
      setGuideName('');
      setDescription('');
      setSteps([]);
      setShowSecondScreen(false);
      setSelectedElementXPath('')
    } else {
      console.error('Failed to save guide:', response.errorMessage || response);
    }
  } catch (error) {
    console.error('Error saving guide:', error);
  }
};



  const handleTooltipClick = () => {
    document.body.addEventListener('mouseover', highlightElement);
    document.body.addEventListener('mouseout', removeHighlightElement);
    document.body.addEventListener('click', selectElement, true);
  };

  let previousHoveredElement: HTMLElement | null = null;

  const highlightElement = (event: MouseEvent) => {
    const target = event.target as HTMLElement;
    if (target.closest('.MuiDrawer-paper') || target.closest('.MuiDialog-paper')) return; // Prevent highlighting in the drawer and dialog
    if (previousHoveredElement && previousHoveredElement !== target) {
      previousHoveredElement.style.boxShadow = '';
      previousHoveredElement.style.zIndex = '';
    }
    target.style.boxShadow = '0 0 10px 5px rgba(255, 0, 0, 0.5)';
    target.style.zIndex = '1000'; // Ensure the element is on top
    previousHoveredElement = target;
  };

  const removeHighlightElement = (event: MouseEvent) => {
    const target = event.target as HTMLElement;
    if (target.closest('.MuiDrawer-paper') || target.closest('.MuiDialog-paper')) return; // Prevent removing highlight in the drawer and dialog
    if (previousHoveredElement && previousHoveredElement === target) {
      target.style.boxShadow = '';
      target.style.zIndex = '';
      previousHoveredElement = null;
    }
  };

  const selectElement = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();

    const target = event.target as HTMLElement;
    if (target.closest('.MuiDrawer-paper') || target.closest('.MuiDialog-paper')) return; // Prevent selecting elements in the drawer and dialog

    if (previousSelectedElement) {
      previousSelectedElement.style.outline = '';
    }
    target.style.outline = '2px solid blue';
    setPreviousSelectedElement(target);

    const xpath = getXPath(target);
    setSelectedElementXPath(xpath);

    document.body.removeEventListener('mouseover', highlightElement);
    document.body.removeEventListener('mouseout', removeHighlightElement);
    document.body.removeEventListener('click', selectElement, true);

    handleDialogOpen();
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Drawer
        variant="persistent"
        anchor="left"
        open={open}
        sx={{
          width: 300,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: 300, boxSizing: 'border-box', backgroundColor: '#1A1A2E', color: '#FFFFFF' },
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', padding: 2, backgroundColor: '#273142', color: '#FFFFFF', justifyContent: 'center' }}>
          <Typography noWrap component="div" sx={{ fontWeight: 'bold' }}>
            QuickAdopt
          </Typography>
        </Box>

        {showSecondScreen ? (
          <Box sx={{ padding: 2 }}>
            <IconButton onClick={handleBack} sx={{ color: '#FFFFFF', mb: 2 }}>
              <ArrowBack />
            </IconButton>
            <Typography variant="h6" sx={{ color: '#FFFFFF', mb: 2 }}>{guideName}</Typography>
            <Typography variant="subtitle1" sx={{ color: '#FFFFFF', mb: 2 }}>Create a new step</Typography>
            <TextField
              variant="outlined"
              placeholder="Search for a template"
              fullWidth
              sx={{ mb: 2 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search sx={{ color: '#FFFFFF' }} />
                  </InputAdornment>
                ),
                sx: {
                  borderRadius: '8px',
                  backgroundColor: 'rgba(255, 255, 255, 0.1)',
                  borderColor: 'transparent',
                  color: '#FFFFFF',
                  height: '30px'
                },
              }}
            />
            <Box sx={{ display: 'flex', gap: 1 }}>
              <Button variant="contained" sx={{ backgroundColor: '#273142', color: '#FFFFFF', borderRadius: '16px', fontSize: '11px', textTransform: 'none' }} onClick={handleAnnouncementClick}>Announcement</Button>
              <Button variant="contained" sx={{ backgroundColor: '#273142', color: '#FFFFFF', borderRadius: '16px', fontSize: '11px', textTransform: 'none' }} onClick={handleTooltipClick}>Tooltip</Button>
              <Button variant="contained" sx={{ backgroundColor: '#273142', color: '#FFFFFF', borderRadius: '16px', fontSize: '11px', textTransform: 'none' }}>Banners</Button>
            </Box>
            {showTemplate && (
              <Box sx={{ marginTop: 2 }}>
                <Button variant="contained" sx={{ backgroundColor: '#1E88E5', color: '#FFFFFF', borderRadius: '16px', fontSize: '11px', textTransform: 'none' }} onClick={handleDialogOpen}>
                  Create from Scratch
                </Button>
              </Box>
            )}
            <Typography variant="subtitle1" sx={{ color: '#FFFFFF', mt: 2 }}>Steps: {steps.length}</Typography>
            <Button variant="contained" sx={{ backgroundColor: '#1E88E5', color: '#FFFFFF', width: '100%' }} onClick={handleSaveGuide}>
              Save Guide
            </Button>
          </Box>
        ) : (
          <Box sx={{ padding: 2 }}>
            <Typography sx={{ color: '#FFFFFF', mb: 2 }}>Create a New Guide</Typography>
            <Typography sx={{ fontSize: '12px', marginLeft: '5px' }}>Guide Name</Typography>
            <TextField
              variant="outlined"
              fullWidth
              value={guideName}
              onChange={(e) => setGuideName(e.target.value)}
              sx={{ mb: 2 }}
              InputLabelProps={{
                style: { color: '#FFFFFF' },
              }}
              InputProps={{
                sx: {
                  borderRadius: '10px',
                  backgroundColor: 'rgba(255, 255, 255, 0.1)',
                  borderColor: 'transparent',
                  color: '#FFFFFF',
                  height: '35px',
                },
              }}
            />
            <Typography sx={{ fontSize: '12px', marginLeft: '5px' }}>Description</Typography>
            <TextField
              variant="outlined"
              fullWidth
              multiline
              rows={3}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              sx={{ mb: 2 }}
              InputLabelProps={{
                style: { color: '#FFFFFF' },
              }}
              InputProps={{
                sx: {
                  borderRadius: '10px',
                  backgroundColor: 'rgba(255, 255, 255, 0.1)',
                  borderColor: 'transparent',
                  color: '#FFFFFF',
                },
              }}
            />
            <Typography sx={{ fontSize: '12px', marginLeft: '5px' }}>Targeting</Typography>
            <Grid container spacing={2} sx={{ mb: 2 }}>
              <Grid item xs={4}>
                <FormControl variant="outlined" fullWidth>
                  <Select
                    value={targeting}
                    onChange={() => handleTargetingChange}
                    sx={{
                      borderRadius: '10px',
                      backgroundColor: 'rgba(255, 255, 255, 0.1)',
                      color: '#FFFFFF',
                      height: '30px',
                    }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          bgcolor: '#1A1A2E',
                          '& .MuiMenuItem-root': {
                            color: '#FFFFFF',
                            height: '30px',
                          },
                        },
                      },
                    }}
                  >
                    <MenuItem value="equals">equals</MenuItem>
                    <MenuItem value="contains">contains</MenuItem>
                    <MenuItem value="startsWith">starts with</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={8}>
                <TextField
                  variant="outlined"
                  placeholder="Target URL"
                  fullWidth
                  value={url}
                  onChange={(e) => setUrl(e.target.value)}
                  InputLabelProps={{
                    style: { color: '#FFFFFF' },
                  }}
                  InputProps={{
                    sx: {
                      borderRadius: '10px',
                      backgroundColor: 'rgba(255, 255, 255, 0.1)',
                      borderColor: 'transparent',
                      color: '#FFFFFF',
                      height: '30px',
                    },
                  }}
                />
              </Grid>
            </Grid>
            <Button variant="text" sx={{ color: '#1E88E5', mb: 2 }}>
              + Add a New Target
            </Button>
            <Button variant="contained" sx={{ backgroundColor: '#1E88E5', color: '#FFFFFF', width: '100%', mb: 2 }} onClick={handleNextScreen}>
              Next
            </Button>
          </Box>
        )}
      </Drawer>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
          width: open ? 'calc(100% - 300px)' : '100%',
          transition: 'width 0.3s',
        }}
      >
        <IconButton
          onClick={handleDrawerToggle}
          sx={{
            height: '50px',
            width: '25px',
            position: 'fixed',
            left: open ? 300 : 0,
            top: "50%",
            zIndex: 1300,
            backgroundColor: '#1A1A2E',
            color: '#FFFFFF',
            borderRadius: '6px',
            borderTopLeftRadius: '0',
            borderBottomLeftRadius: '0',
            transform: 'translateY(-50%)',
            '&:hover': {
              backgroundColor: '#1A1A2E',
            },
          }}
        >
          {open ? <ChevronLeft /> : <ChevronRight />}
        </IconButton>
        <Typography variant="h4">Main Content Area</Typography>
      </Box>

      <Dialog open={openDialog} onClose={handleDialogClose}
        sx={{ 
          '& .MuiDialog-paper': { 
            width: '400px', // Set your desired width
            height: '280px', // Set your desired height
            maxWidth: 'none', // Disable the default maxWidth
          } 
        }}
      >
        <DialogContent>
          <Box sx={{ textAlign: 'center', mb: 2 }}>
            <input
              accept="image/*"
              style={{ display: 'none' }}
              id="raised-button-file"
              multiple
              type="file"
              onChange={handleFileChange}
            />
            <label htmlFor="raised-button-file">
              <Button variant="contained" component="span" startIcon={<CloudUpload />}>
                Upload File
              </Button>
            </label>
          </Box>
          <TextField
            autoFocus
            margin="dense"
            label="Step Title"
            type="text"
            fullWidth
            value={stepTitle}
            onChange={(e) => setStepTitle(e.target.value)}
            sx={{ mb: 2 }}
          />
          <TextField
            margin="dense"
            label="Enter Text"
            type="text"
            fullWidth
            value={text}
            onChange={(e) => setText(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSaveStep} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default CreateGuide;
