import React, { useState, useEffect, ChangeEvent } from "react";
import { getAllOrganizations } from "../../services/OrganizationService";
import { SubmitAccountDetails, fetchAccountsById } from "../../services/AccountService";
import { adminApiService, adminUrl } from "../../services/APIService";
import { TextField, Select, Button, IconButton, Snackbar, Alert, Grid, FormControl, InputLabel, Dialog, DialogTitle, DialogContent, SelectChangeEvent, OutlinedInput, Checkbox, ListItemText, MenuItem } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useSnackbar } from "../../SnackbarContext";
import styles from "./AccountStle.module.scss";
import { useAuth } from "../auth/AuthProvider";
import { User } from "../../models/User";
import { saveUserRoles } from "../../services/UserRoleService";
import { useTranslation } from "react-i18next";

type InputFields = {
	AccountName: string;
};
type ErrorFields = Partial<InputFields>;

const RolePopup = (props: any) => {
	const {
		showDeletePopup,
		setShowDeletePopup,
		selectedAccount,
		selectedEmail,
		roles,
		emailToUserIdMap,
		responseOfUserRoleList,
		organizationUsers,
		dialogOpen,
		setDialogOpen,
		accounts,
		showEditPopup,
		setShowEditPopup,
		accountidedit,
		GetAllAccounts,
		setModels,
		setLoading,
		setTotalcount,
		orderByField,
		filters,
        setFilters,
        fetchUserRoles,
	} = props;
	const { t: translate } = useTranslation();
	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">("success");
	const { signOut, userDetails } = useAuth();
	const [OrganizationId, setOrganizationId] = useState(userDetails?.OrganizationId??"");
	const { openSnackbar } = useSnackbar();

	const handleSnackbarClose = () => {
		setSnackbarOpen(false);
	};
	const [localSelectedEmail, setLocalSelectedEmail] = useState<string[]>([]);
	const [localSelectedRoles, setLocalSelectedRoles] = useState<{ RoleName: string, RoleId: string }[]>([]);
	const [localSelectedAccount, setLocalSelectedAccount] = useState<{ id: string, name: string } | null>(selectedAccount);
	useEffect(() => {
		if (selectedEmail) {
			setLocalSelectedEmail([selectedEmail].filter(Boolean));
		}
		setLocalSelectedAccount(selectedAccount);
	}, [selectedAccount, selectedEmail]);
	const handleRoleChange = (event: SelectChangeEvent<string[]>) => {
		const selectedRoleNames = event.target.value as string[];
		const selectedRoleObjects = selectedRoleNames.map(roleName => {
			const role = roles.find((r:any) => r.RoleName === roleName);
			return { RoleName: roleName, RoleId: role?.RoleId || '' };
		});
		setLocalSelectedRoles(selectedRoleObjects);
	};
	const handleCloseDialog = () =>
	{
		setDialogOpen(false);
		}
	const handleUserChange = (event: SelectChangeEvent<string[]>) => {
		setLocalSelectedEmail((event.target.value as string[]).filter(Boolean));
	};

	const handleSave = async () => {
		try {

			const rolesData = [];
			for (const email of localSelectedEmail) {
				if (!email) continue;
				const userId = emailToUserIdMap[email];
				const existingRolesResponse = responseOfUserRoleList;
				const existingRoles = existingRolesResponse.filter((role: any) => role.EmailId === email);
				const existingRoleNames = new Set(existingRoles.map((role: any) => role.RoleName));

				const duplicateRoles = localSelectedRoles.filter(role => existingRoleNames.has(role.RoleName));

				const userRolesData = localSelectedRoles.map(role => ({
					// Id: generateCustomUserId(),
					RoleId: role.RoleId,
					RoleName: role.RoleName,
					organizationId: OrganizationId,
					userId: userId,
					AccountId: localSelectedAccount ? localSelectedAccount.id : null,
					CreatedDateTime: new Date().toISOString()
				}));
				rolesData.push(...userRolesData);
			}
			await saveUserRoles(rolesData, openSnackbar);

			fetchUserRoles();
		} catch (error) {

			console.error('Failed to save data', error);
		}
		handleCloseDialog();
	};
	const isSaveButtonDisabled = () => {
		return localSelectedEmail.length === 0 || localSelectedRoles.length === 0 || !localSelectedAccount;
	};
   


	return (
		dialogOpen && (
			<div className="qadpt-modal-overlay">
			<div className="qadpt-roleeditpopup"
			>
            <div className="qadpt-title">
               Manage User Roles
				</div>
				<svg
						onClick={() => setDialogOpen(false)}
						className="qadpt-closeicon"
						xmlns="http://www.w3.org/2000/svg"
						x="0px"
						y="0px"
						width="24"
						height="24"
						viewBox="0 0 50 50"
					>
						<path d="M 7.71875 6.28125 L 6.28125 7.71875 L 23.5625 25 L 6.28125 42.28125 L 7.71875 43.71875 L 25 26.4375 L 42.28125 43.71875 L 43.71875 42.28125 L 26.4375 25 L 43.71875 7.71875 L 42.28125 6.28125 L 25 23.5625 Z"></path>
					</svg>
            <div className="qadpt-addrole">
                <Grid container spacing={2}>
				<Grid item
                            xs={12} 
                        >
                            <FormControl fullWidth required >
                                <InputLabel id="select-user-label">{translate('Select User')}</InputLabel>
                                <Select
                                   MenuProps={{
                                    PaperProps: {
                                      className: "qadpt-rolemenu",
                                    },
                                  }}
                                    labelId="select-user-label"
                                    multiple
                                    value={localSelectedEmail}
                                    onChange={handleUserChange}
                                    label={translate('Select User')}
                                    renderValue={(selected) => (selected as string[]).filter(Boolean).join(', ')}
                                  //  disabled={isReadOnly}
                                >
                                    {organizationUsers.map((user:any) => (
                                        <MenuItem key={user} value={user}>
                                            <Checkbox checked={localSelectedEmail.includes(user)} />
                                            <ListItemText
                                                // sx={{
                                                // "&. MuiPopover-paper-MuiMenu-paper": {
                                                //     height: "344px",
                                                //     marginTop: "205px"
                                                // }
                                                // }}
                                                primary={user} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel id="select-account-label">{translate('Select Account')}</InputLabel>
                                <Select
                                    required
                                    labelId="select-account-label"
                                    value={localSelectedAccount ? localSelectedAccount.id : ''}
                                    onChange={(e) => {
                                        const selectedId = e.target.value;
                                        const selectedAccount = accounts.find((account:any) => account.id === selectedId);
                                        setLocalSelectedAccount(selectedAccount || null);
                                    }}
                                    label={translate('Select Account')}
                                    MenuProps={{
                                        PaperProps: {
                                          className: "qadpt-rolemenu",
                                        },
                                      }}
                                  //  disabled={isReadOnly}
                                >
                                    {accounts.map((account:any) => (
                                        <MenuItem key={account.id} value={account.id}>{account.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl required fullWidth>
                                <InputLabel id="roles-label">{translate('Roles')}</InputLabel>
                                <Select
                                    labelId="roles-label"
                                    id="roles-select"
                                    multiple
										value={localSelectedRoles.map(role => role.RoleName)}
										MenuProps={{
											PaperProps: {
											  className: "qadpt-rolemenu",
											},
										  }}
                                    onChange={handleRoleChange}
                                    input={<OutlinedInput label="Roles" />}
                                    renderValue={(selected) => (selected as string[]).join(', ')}
                                >
                                    {roles.map((role:any) => (
                                        <MenuItem key={role.RoleName} value={role.RoleName}>
                                            <Checkbox checked={localSelectedRoles.some(selectedRole => selectedRole.RoleName === role.RoleName)} />
                                            <ListItemText primary={role.RoleName} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
						</Grid>
				</div>
				<div className="qadpt-role-buttons">
    <Button onClick={() => setDialogOpen(false)}>{translate('Cancel')}</Button>
	<Button
    className={`qadpt-conform-button ${isSaveButtonDisabled() ? 'qadpt-disabled' : ''}`}
    disabled={isSaveButtonDisabled()}
    onClick={handleSave}
    variant="contained"
    color="primary"
>
    {translate('Save')}
</Button>

</div>

           
				</div>
				</div>
		)
	);
};

export default RolePopup;




