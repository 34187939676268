import React, { createContext, useContext, useState, ReactNode } from "react";
import { Snackbar, Alert } from "@mui/material";
import CelebrationOutlinedIcon from "@mui/icons-material/CelebrationOutlined";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import LockOpenIcon from '@mui/icons-material/LockOpen';
import NoAccountsOutlinedIcon from '@mui/icons-material/NoAccountsOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined'; 
import KeyOutlinedIcon from '@mui/icons-material/KeyOutlined';
import CopyAllOutlinedIcon from '@mui/icons-material/CopyAllOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
type SnackbarContextType = {
	openSnackbar: (message: string, severity: "success" | "error") => void;
};

const SnackbarContext = createContext<SnackbarContextType | undefined>(undefined);

export const useSnackbar = () => {
	const context = useContext(SnackbarContext);
	if (!context) {
		throw new Error("useSnackbar must be used within a SnackbarProvider");
	}
	return context;
};

export const SnackbarProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">("success");
	const [snackbarIcon, setSnackbarIcon] = useState<JSX.Element>(<CelebrationOutlinedIcon />); // Default icon

	const openSnackbar = (message: string, severity: "success" | "error") => {
		setSnackbarMessage(message);
		setSnackbarSeverity(severity);
		
		// Check if message contains "unblock account" and set the icon accordingly
		if (message.toLowerCase().includes("unblocked")) {
			setSnackbarIcon(<LockOpenIcon />);
		}
		else if (message.toLowerCase().includes("disabled")) {
			setSnackbarIcon(<NoAccountsOutlinedIcon />);
		} 
		else if (message.toLowerCase().includes("enabled")) {
			setSnackbarIcon(<AccountCircleOutlinedIcon />);
		} 
		else if (message.toLowerCase().includes("reset password")) {
			setSnackbarIcon(<KeyOutlinedIcon sx={{transform:"rotate(90deg)"}} />);
		}
		else if (message.toLowerCase().includes("deleted")) {
			setSnackbarIcon(<i className="fal fa-trash-alt"></i>); 
		}
		else if (message.toLowerCase().includes("copied")) {
			setSnackbarIcon(<CopyAllOutlinedIcon />); 
		}
		else if (message.toLowerCase().includes("blocked")) {
			setSnackbarIcon(<LockOutlinedIcon />); 
		}
		else {
			setSnackbarIcon(severity === "success" ? <CelebrationOutlinedIcon /> : <ErrorOutlineOutlinedIcon />);
		}

		setSnackbarOpen(true);
	};

	const handleSnackbarClose = () => {
		setSnackbarOpen(false);
	};

	return (
		<SnackbarContext.Provider value={{ openSnackbar }}>
			{children}
			<Snackbar
				className={`qadpt-toaster ${snackbarSeverity === 'success' ? 'qadpt-toaster-success' : 'qadpt-toaster-error'}`}
				open={snackbarOpen}
				autoHideDuration={4000}
				onClose={handleSnackbarClose}
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
			>
				<Alert
					onClose={handleSnackbarClose}
					severity={snackbarSeverity}
					className="qadpt-alert"
					icon={snackbarIcon} // Use the dynamic icon
				>
					{snackbarMessage}
				</Alert>
			</Snackbar>
		</SnackbarContext.Provider>
	);
};
