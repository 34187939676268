
// -----------------------Account APIs -----------------------
import { Status } from "../models/Status";
import { adminApiService, userApiService }  from "./APIService";


let AccountDeleteDetails = {
	AccountId: "",
	AccountName: "",
	AccountType: "",
	CreatedBy: "",
	CreatedDate: "",
	OrganizationId: "",
	UpdatedBy: "",
	UpdatedDate: "",
	Active: Boolean(true),
};

const adminUrl = process.env.REACT_APP_ADMIN_API

export const GetAllAccounts = async (setModels: any, setLoading: any) => {
	try {
		const response = await adminApiService.get("/Account/GetAllAccounts");
		let apiData = response.data;

		if (Array.isArray(apiData)) {
			apiData = apiData.map((account) => ({
				...account,
				CreatedDate: account.CreatedDate.split("T")[0],
				UpdatedDate: account.UpdatedDate ? account.UpdatedDate.split("T")[0] : null,
			}));
			setModels(apiData);
		} else {
			console.error("Invalid data format from API:", apiData);
		}
	} catch (error) {
		console.error("Error fetching Accounts:", error);
	} finally {
		//setLoading(false);
	}
};

export const GetAccountsList = async (
	setModels: any,
	setLoading: any,
	OrganizationId: any,
	skip: any,
	top: any,
	setTotalcount: any,
	orderByField: any,
	filters: any
) => {
	try {
		setLoading(true);
		const requestBody = {
			skip,
			top,
			filters: filters ? filters : "", // Assuming an empty array for now, adjust as necessary
			orderByFields: orderByField, // Assuming an empty string for now, adjust as necessary
		};
		const response = await adminApiService.post(`/Account/GetAccountsByOrgId`, requestBody);

		let apiData = response.data.results;
		setTotalcount(response.data._count);

		if (Array.isArray(apiData)) {
			apiData = apiData.map((account) => ({
				...account,
				CreatedDate: account.CreatedDate.split("T")[0],
				UpdatedDate: account.UpdatedDate ? account.UpdatedDate.split("T")[0] : null,
			}));
			setModels(apiData);
		} else {
			console.error("Invalid data format from API:", apiData);
		}
	} catch (error) {
		console.error("Error fetching Accounts:", error);
	} finally {

	}
};


export const SubmitCreateAccount = async (
	setLoading: any,
	setShowPopup: any,
	setModels: any,
	inputs: any,
	OrganizationId: any,
	skip: any,
	top: any,
	setTotalcount: any,
	openSnackbar: any,
	orderByField: any,
	filters: any
) => {
	try {
		setLoading(true);
		const response = await adminApiService.post(`/Account/CreateAccount`,inputs);
		const responseData = response.data;
		if (responseData.Success) {
			
				openSnackbar(responseData.SuccessMessage, "success");
			
			setShowPopup(false);
			GetAccountsList(setModels, setLoading, OrganizationId, skip, top, setTotalcount, orderByField, filters);
		} else {
			
				openSnackbar(responseData.ErrorMessage, "error");
			
		}
	} catch (error: any) {
		console.error("Error creating account:", error);
		openSnackbar(`Error creating account: ${error.message}`, "error");
	} finally {
		setLoading(false);
	}
};

export const SubmitAccountDetails = async (
	setLoading: any,
	setModels: any,
	setShowEditPopup: any,
	AccountDetails: any,
	organizationId: any,
	skip: any,
	top: any,
	setTotalcount: any,
	openSnackbar: any,
	orderByField: any,
	filters: any
) => {
	try {
		const response = await adminApiService.put(`/Account/Update`, AccountDetails);

		const responseData = response.data;
		if (responseData.Success) {
				openSnackbar(responseData.SuccessMessage, "success");
			
			GetAccountsList(setModels, setLoading, organizationId, skip, top, setTotalcount, orderByField, filters);
			setShowEditPopup(false);
		} else {
			openSnackbar(responseData.ErrorMessage, "error");
		}
	} catch (error: any) {
		throw error;
	} finally {

	}
};

export const fetchDeleteAccountDetails = async (
	accountidedit: any,
	setLoading: any,
	setModels: any,
	setShowDeletePopup: any,
	OrganizationId: any,
	skip: any,
	top: any,
	setTotalcount: any,
	setSnackbarMessage: any,
	setSnackbarSeverity: any,
	setSnackbarOpen: any,
	openSnackbar: any,
	orderByField: any,
	filters: any
) => {
	try {
		const response = await adminApiService.get(`/Account/GetAccountById?accountId=${accountidedit}`);
		const responseData = response.data;
		if (!responseData) {
			throw new Error("Network response was not ok");
		}

		AccountDeleteDetails = {
			AccountId: responseData.AccountId,
			AccountName: responseData.AccountName,
			AccountType: responseData.AccountType,
			CreatedBy: responseData.CreatedBy,
			CreatedDate: responseData.CreatedDate,
			OrganizationId: responseData.OrganizationId,
			UpdatedBy: responseData.UpdatedBy,
			UpdatedDate: responseData.UpdatedDate,
			Active: false,
		};
		DeleteAccountDetails(
			setLoading,
			setModels,
			setShowDeletePopup,
			AccountDeleteDetails,
			OrganizationId,
			skip,
			top,
			setTotalcount,
			setSnackbarMessage,
			setSnackbarSeverity,
			setSnackbarOpen,
			openSnackbar,
			orderByField,
			filters
		);
	} catch (error) {
		console.error("Failed to fetch user details:", error);
	}
};

export const DeleteAccountDetails = async (
	setLoading: any,
	setModels: any,
	setShowDeletePopup: any,
	AccountDeleteDetails: any,
	OrganizationId: any,
	skip: any,
	top: any,
	setTotalcount: any,
	setSnackbarMessage: any,
	setSnackbarSeverity: any,
	setSnackbarOpen: any,
	openSnackbar: any,
	orderByField: any,
	filters: any
) => {
	try {
		const response = await adminApiService.put(`/Account/Delete`, AccountDeleteDetails);
		const responseData = response.data;

		if (responseData) {
			if (responseData.Success) {
				
				openSnackbar(responseData.SuccessMessage, "error");
			} else {

				openSnackbar(responseData.ErrorMessage, "error");
			}
			GetAccountsList(setModels, setLoading, OrganizationId, skip, top, setTotalcount, orderByField, filters);
			setShowDeletePopup(false);
		}
	} catch (error: any) {
		setSnackbarMessage(`Error Deleting user: ${error.message}`);
		setSnackbarSeverity("error");
		setSnackbarOpen(true);
	}
};

// ---------------------Guide APIs -------------------------

export const saveGuide = async (guideData : any) => {
    const formattedGuideData = {
        GuideId: guideData.GuideId,
        Title: guideData.Title,
        Content: guideData.Content,
        OrganizationId: guideData.OrganizationId,
        CreatedDate: guideData.CreatedDate,
        UpdatedDate: guideData.UpdatedDate,
        CreatedBy: guideData.CreatedBy,
        UpdatedBy: guideData.UpdatedBy,
        GuideStep: guideData.GuideStep.map((step : any) => ({
            StepTitle: step.StepTitle,
            Text: step.Text,
            Element: step.Element,
            On: step.On,
            Image: step.Image,
            BackgroundColor: step.BackgroundColor,
            Id: step.Id,
            Arrow: step.Arrow,
            Classes: step.Classes,
            IsClickable: step.IsClickable,
        }))
    };

    try {
        const response = await adminApiService.post<Status>("/Guide/Saveguide", formattedGuideData);
        return response.data;
    } catch (error) {
        console.error("Error saving guide:", error);
        throw error;
    }
};


export const getAllGuides = async (): Promise<any[]> => {
	try {
	  const response = await adminApiService.get("/Guide/GetAllguides");
	  return response.data;
	} catch (error) {
	  console.error("Error fetching guides:", error);
	  throw error;
	}
  };
  export const deleteGuide = async (guideId: string): Promise<void> => {
    try {
        const response = await adminApiService.post(`/Guide/Deleteguide?guideId=${guideId}`);
        if (response.status === 204) {
        } else {
            console.error('Failed to delete guide');
        }
    } catch (error) {
        throw error;
    }
};


export const GetAllAccountsList = async (
	skip: number,
	top: number,
	orderByFields: string,
	filters: any[],
	organization: string,
	//setTotalcount: (count: number) => void
  ): Promise<any[]> => {
	try {
	  const response = await adminApiService.post("/Account/GetAllAccounts", {
		skip,
		top,
		orderByFields,
		filters, 
		organization
	  });
	  const apiData = response.data.results;
	  if (Array.isArray(apiData)) {
		return apiData;
	  } else {
		console.error("Invalid data format from API:", apiData);
		return [];
	  }
	} catch (error) {
	  console.error("Error fetching Accounts:", error);
	  return [];
	}
  };
  
  export const fetchAccountsById = async (
	id:any
) => {
	try {
		const response = await adminApiService.get(`/Account/GetAccountById?accountId=${id}`);
		const responseData = response.data;
		if (!responseData) {
			throw new Error("Network response was not ok");
		}
		return responseData;
		
	} catch (error) {
		console.error("Failed to fetch user details:", error);
	}
};





export const GetAccountsListById= async (
	skip: number,
	top: number,
	orderByFields: string,
	filters: any[],
	organization: string,
	//setTotalcount: (count: number) => void
  ): Promise<any[]> => {
	try {
	  const response = await adminApiService.post("/Account/GetAccountsByOrgId", {
		skip,
		top,
		orderByFields,
		filters, 
		organization
	  });
	  const apiData = response.data.results;
	  if (Array.isArray(apiData)) {
		return apiData;
	  } else {
		console.error("Invalid data format from API:", apiData);
		return [];
	  }
	} catch (error) {
	  console.error("Error fetching Accounts:", error);
	  return [];
	}
  };