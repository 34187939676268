import userManager from '../components/auth/UseAuth';
import { User } from "../models/User";
import { adminApiService, userApiService, adminUrl, userUrl } from "./APIService";
import { useSnackbar } from "../SnackbarContext";
import { JSEncrypt } from 'jsencrypt';

// --------------------------------User APIs --------------------
const headers =  {
	headers: {
	  'Content-Type': 'application/json',
	}
  }
export const getAllUsers = async (): Promise<User[] | null> => {
	try {
		const response = await adminApiService.get<User[]>("/User/GetAllUsersForAllOrganizations");
		return response.data;
	} catch (error) {

		throw error;
	}
};
export const GetUserDetails = async () => {
	try {
		const response = await adminApiService.get<User>("/User/GetUserDetails");
		return response;
	} catch (error) {

		return null;
	}
};
export const GetUserDetailsById = async (id: any) => {
	try {
		const response = adminApiService.get(`/User/GetUserByUserId?id=${id}`);
		if (!response) {
			throw new Error("Network response was not ok");
		}
		return response;
	}
	catch (error) {

		return null;
		
	}
}

export const GetUserByEmail = async (EmailId: string): Promise<User | null> => {
	try {
		const response = await adminApiService.get<User>(`/User/GetUserByEmail?emailId=${EmailId}`);
		return response.data;
	} catch (error) {
		userManager.signoutRedirect();
		return null;
	}
};

export const fetchUserDataFromApi = async (setModels: any, setLoading: any) => {
	try {
		setLoading(true);
		const response = await adminApiService.get("/User/GetAllUsersForAllOrganizations");
		const apiData = response.data;

		if (Array.isArray(apiData)) {
			setModels(apiData);
		} else {

		}
	} catch (error) {
		console.error("Error fetching models:", error);
	} finally {
		setLoading(false);
	}
};

export const fetchUsersList = async (
	setModels: any,
	setLoading: any,
	OrganizationId: any,
	skip: any,
	top: any,
	setTotalcount: any,
	orderByField: any,
	filters: any,

) => {
	try {
		setLoading(true);
		const requestBody = {
			skip,
			top,
			filters: filters,
			orderByFields: orderByField ? orderByField : "", 

		};
		const response = await adminApiService.post(`/User/GetOrganizationUsers`, requestBody);
		const apiData = response.data.results;
		setTotalcount(response.data._count);

		if (Array.isArray(apiData)) {
			const usernames = apiData.map(user => user.UserName);
			setModels(apiData);
			
		  } else {
		  }
	} catch (error) {
	} finally {
	}
};

export const SubmitCreateUser = async (
	setLoading: any,
	setShowPopup: any,
	setModels: any,
	setShowEditPopup: any,
	inputs: any,
	OrganizationId: any,
	skip: any,
	top: any,
	setTotalcount: any,
	openSnackbar: any,
	orderByFields: any,
	filters: any,
	setApiError: any,
) => {
	try {
		const encryptedPassword = encryptPassword(inputs.Password);
		const userCreateCommand = {
			...inputs,
			Password: encryptedPassword,
		};
		setLoading(true);
		const response = await adminApiService.post(`/User/CreateUser`, userCreateCommand);
		const responseData = response.data;

		if (responseData) {
			
			if (responseData.Success) {
				openSnackbar(responseData.SuccessMessage, "success");
				setShowPopup(false);
			 } 
			else {
				setApiError(responseData.ErrorMessage);
				openSnackbar(responseData.ErrorMessage, "error");
				setShowPopup(true);
			}
			setShowEditPopup(false);
			fetchUsersList(setModels, setLoading, OrganizationId, skip, top, setTotalcount, orderByFields, filters);
		} else {
			console.error("Failed to create user");
		}
	} catch (error) {
		console.error("Error creating user:", error);
	} finally {
		setLoading(false);
	}
};

export const SubmitCreatenewUser = async (
	setLoading: any,
	setShowPopup: any,
	setModels: any,
	setShowEditPopup: any,
	inputs: any,
	setSnackbarOpen: any,
	setSnackbarMessage: any,
	setSnackbarSeverity: any,

	onSuccess: () => void
) => {
	setLoading(true);
	try {
		const response = await adminApiService.post(`/User/CreateUser`, inputs);
		const responseData = response.data;
		if (!responseData.Success) {
			throw new Error("Network response was not ok");
		}
		setShowEditPopup(false);
		setShowPopup(false);
		fetchUserDataFromApi(setModels, setLoading);
		setSnackbarMessage("Admin created successfully");
		setSnackbarSeverity("success");
		setSnackbarOpen(true);
		setTimeout(onSuccess, 5000);
		onSuccess();
	} catch (error) {
		console.error("Error creating user:", error);
		setSnackbarSeverity("error");
		setSnackbarMessage("Failed to create Admin");
		setSnackbarOpen(true);
	}
};

export const Fetchuserbyid = async (id: string) => {
	const response = await adminApiService.get(`/User/GetUserByUserId?id=${id}`);
	const responseData = response.data;
	if (!responseData) {
		throw new Error("Network response was not ok");
	}
	return responseData;
};

export const SubmitUserDetail = async (setLoading: any, setModels: any, setShowEditPopup: any, userDetails: any) => {
	setLoading(true);
	const response = await adminApiService.post(`/User/UpdateUser`, userDetails);
	const responseData = response.data;
	if (responseData.Success) {

		setShowEditPopup(false);
		fetchUserDataFromApi(setModels, setLoading);
	} else {

		console.error("Failed to update user details");
	}
};

export const SubmitUserDetails = async (
	setLoading: any,
	setModels: any,
	setShowEditPopup: any,
	userDetails: any,
	skip: any,
	top: any,
	OrganizationId: any,
	setTotalcount: any,
	openSnackbar: any,
	orderByFields: any,
	filters: any,
	setApiError: any,

) => {
	try {
		setLoading(true);
		const response = await adminApiService.post(`/User/UpdateUser`, userDetails);
		const responseData = response.data;
		if (responseData) {
		
			if (responseData.Success) {
				
				openSnackbar(responseData.SuccessMessage, "success");

			} else {
				openSnackbar(responseData.ErrorMessage, "error");
				setApiError(responseData.ErrorMessage);
			}
			setShowEditPopup(false);


			fetchUsersList(setModels, setLoading, OrganizationId, skip, top, setTotalcount, orderByFields, filters);
		} else {
		}
	} catch (error: any) {
	}
};

export const DeleteUserDetails = async (
	emailiddelete: any,
	setShowDeletePopup: any,
	fetchUserDataFromApi: any,
	setModels: any,
	setLoading: any
) => {
	setLoading(true);
	adminApiService.post(`/User/DeleteUser?emailId=${emailiddelete}`, emailiddelete)
		.then((response) => {
			if (!response.data.Success) {
				throw new Error("Network response was not ok");
			}
			setShowDeletePopup(false);
			fetchUserDataFromApi(setModels, setLoading);
		})
		.catch((error) => {
			console.error("Error Deleting user:", error);
		});
};

export const Submitdisableuser = async (setLoading: any, setModels: any, userid: any) => {
	const response = await adminApiService.post(`/User/CanUserBeDeactivated`, userid);
	const responseData = response.data;

	if (responseData.Success) {
		fetchUserDataFromApi(setModels, setLoading);
	} else {
	}
};
const publicKey = process.env.REACT_APP_PUBLIC_ENCRYPT_KEY ? process.env.REACT_APP_PUBLIC_ENCRYPT_KEY : "";
let pchange: any;
export const encryptPassword = (password: string) => {
	const encrypt = new JSEncrypt();
	encrypt.setPublicKey(publicKey);
	const encryptedPassword = encrypt.encrypt(password);
	

	if (pchange === true) {
		return encryptedPassword ? encodeURIComponent(encryptedPassword) : encryptedPassword;
	} else {
		return encryptedPassword;
	}
};

export const changeUserPassword = async (
	setLoading: any,
	setShowResetPasswordPopup: any,
	userId: any,
	newPassword: any,
	openSnackbar: any
) => {
	setLoading(true); 
	pchange = true;
	const encryptedPasswordnew = encryptPassword(newPassword);
	

	const response = await userApiService.post(`/User/ChangeUserPassword`, encryptedPasswordnew);
	const responseData = response.data;

	if (responseData) {
		
		pchange = false;
		if (responseData.Success) {
			openSnackbar(responseData.SuccessMessage, "success");
		} else {
			openSnackbar(responseData.ErrorMessage, "error");
		}
		setShowResetPasswordPopup(false);
	} else {

		openSnackbar("Failed to change password", "error");
	}

	setLoading(false); 
};


export const confirmEmail = async (
	setModels: any,
	setLoading: any,
	OrganizationId: any,
	skip: any,
	top: any,
	setTotalcount: any,
	userId: any,
	openSnackbar: any,
	orderByFields: any,
	filters: any,
) => {
	setLoading(true);

	const response = await userApiService.post(`/User/ConfirmEmail`,userId,headers)
	const resposeData = response.data;
	if (resposeData.Success) {

		
		openSnackbar(resposeData.SuccessMessage, "success");
	} else {
		openSnackbar(resposeData.ErrorMessage, "error");
	}
	fetchUsersList(setModels, setLoading, OrganizationId, skip, top, setTotalcount, orderByFields, filters);
};
export const BlockUser = async (
	setModels: any,
	setLoading: any,
	OrganizationId: any,
	skip: any,
	top: any,
	setTotalcount: any,
	userId: any,
	openSnackbar: any,
	orderByFields: any,
	filters: any,
) => {
	setLoading(true);

	try {
		const response = await userApiService.post(`/User/BlockUser`,userId,headers);
		const resposeData = response.data;
		if (resposeData.Success) {
			
			
			openSnackbar(resposeData.SuccessMessage, "success");
		}
			else {
				
				openSnackbar(resposeData.ErrorMessage, "error");
			}

			fetchUsersList(setModels, setLoading, OrganizationId, skip, top, setTotalcount, orderByFields, filters);
		} 
	 catch (error) {

		console.error("An error occurred while blocking the user:", error);
	}

	
};

export const UnblockUser = async (
	setModels: any,
	setLoading: any,
	OrganizationId: any,
	skip: any,
	top: any,
	setTotalcount: any,
	userId: any,
	openSnackbar: any,
	orderByFields: any,
	filters: any,
) => {
	setLoading(true); 

	try {
		const response = await userApiService.post(`/User/UnblockUser`,userId,headers);
		const responseData = response.data;
		if (responseData.Success) {
				openSnackbar(responseData.SuccessMessage, "success");
			} else {
				
				openSnackbar(responseData.ErrorMessage, "error");
			}

			fetchUsersList(setModels,setLoading, OrganizationId, skip, top, setTotalcount, orderByFields, filters);
		
	} catch (error) {
		console.error("An error occurred while unblocking the user:", error);
	}

};

export const deactivateUser = async (
	setModels: any,
	setLoading: any,
	OrganizationId: any,
	skip: any,
	top: any,
	setTotalcount: any,
	userId: any,
	openSnackbar: any,
	orderByFields: any,
	filters: any,
) => {
	setLoading(true); 

	try {
		const response = await userApiService.post(`/User/DeActivateUser`,userId,headers);
		const responseData = response.data;
		if (responseData.Success) {
			
				openSnackbar(responseData.SuccessMessage, "success");
			} else {

				openSnackbar(responseData.ErrorMessage, "error");
			}

			fetchUsersList(setModels,setLoading, OrganizationId, skip, top, setTotalcount, orderByFields, filters);
		
	} catch (error) {

		console.error("An error occurred while deactivating the user:", error);
	}

};

export const activateUser = async (
	setModels: any,
	setLoading: any,
	OrganizationId: any,
	skip: any,
	top: any,
	setTotalcount: any,
	userId: string,
	openSnackbar: any,
	orderByFields: any,
	filters: any,
) => {
	setLoading(true); 

	try {
		const response = await userApiService.post(`/User/ActivateUser`,userId,headers );
		const responseData = response.data;
		if (responseData.Success) {

				openSnackbar(responseData.SuccessMessage, "success");
			} else {

				openSnackbar(responseData.ErrorMessage, "error");
			}
			
			fetchUsersList(setModels,setLoading, OrganizationId, skip, top, setTotalcount, orderByFields, filters);
		} 
	catch (error) {
		
		console.error("An error occurred while activating the user:", error);
	}

	
};
  
  
  
