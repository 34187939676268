import React, { useState, useEffect, ChangeEvent, FocusEvent, FormEvent } from "react";
import {
	TextField,
	Button,
	Select,
	MenuItem,
	FormControl,
	InputLabel,
	FormHelperText,
	SelectChangeEvent,
	Snackbar,
	Alert,
	Box,
} from "@mui/material";
import { activateUser } from "../../services/UserService";
import { adminUrl } from "../../services/APIService";
import { useSnackbar } from "../../SnackbarContext";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import { JSEncrypt } from "jsencrypt";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import styles from "./UserStyles.module.scss";
import { useAuth } from "../auth/AuthProvider";

type InputFields = {
	Password: string;
};

type ErrorFields = Partial<InputFields>;

const UserEnable = (props: any) => {
	const {
		enableUserPopup,
		setEnableUserPopup,
		userId,
		fetchUsersList,
		setModels,
		setLoading,
		skip,
		top,
		//Organizationid,
		setTotalcount,
		orderByFields,
		filters,
	} = props;
	const { openSnackbar } = useSnackbar();
	const { signOut, userDetails } = useAuth();
	const [OrganizationId, setOrganizationId] = useState(userDetails?.OrganizationId??"");

	const handleSubmit = async (e: any) => {
		e.preventDefault();

		setLoading(true);
		try {
			await activateUser(
				setModels,
				setLoading,
				OrganizationId,
				skip,
				top,
				setTotalcount,
				userId,
				openSnackbar,
				orderByFields,
				filters
			);
			setEnableUserPopup(false);
			setLoading(false);
		} catch (error) {

		}
	};

	return (
		enableUserPopup && (
			<div className="qadpt-modal-overlay">
			<div className="qadpt-usrconfirm-popup qadpt-success">
  <div>
    <div className="qadpt-icon">
      <AccountCircleOutlinedIcon />
    </div>
  </div>
  <div>
    <div className="qadpt-popup-title">Enable User</div>
   
      <div className="qadpt-warning">
        Enabling will take this user to its previous state with all past roles assigned.
      </div>
      {/* <svg
        onClick={() => setEnableUserPopup(false)}
        className="close-icon"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="24"
        height="24"
        viewBox="0 0 50 50"
      >
        <path d="M 7.71875 6.28125 L 6.28125 7.71875 L 23.5625 25 L 6.28125 42.28125 L 7.71875 43.71875 L 25 26.4375 L 42.28125 43.71875 L 43.71875 42.28125 L 26.4375 25 L 43.71875 7.71875 L 42.28125 6.28125 L 25 23.5625 Z"></path>
      </svg>
    </div> */}
    <form onSubmit={handleSubmit}>
      <div className="qadpt-buttons">
        <button
          onClick={() => setEnableUserPopup(false)}
          className="qadpt-cancel-button"
        >
          Cancel
        </button>
        <button
          className="qadpt-conform-button"
          type="submit"
        >
          Enable
        </button>
      </div>
    </form>
  </div>
</div>
</div>
		)
	);
};

export default UserEnable;
