// feedbackService.ts

import { adminApiService, adminUrl } from "./APIService";

export const saveFeedback = async (feedbackData: {
    UserId: string;
    OrganizationId: string;
    Message: string;
    FeedbackFile: string; // or string[] if you're planning to change it to an array
    Rating: string | number;
    CreatedDate: string;
}) => {
    try {
        const response = await adminApiService.post(`/Feedback/SaveFeedBack`, feedbackData);
        const responseData = response.data;
        if (!responseData) {
            throw new Error('Failed to save feedback');
        }

        return responseData;
    } catch (error) {
        
        throw error; // Rethrow the error to handle it later in the calling code
    }
};
