// src/utils/formatDateTime.ts
import moment from 'moment-timezone';

type DateFormat = 
    | 'dd-MM-yyyy' 
    | 'MM-dd-yyyy' 
    | 'yyyy-MM-dd'
    | 'dd/mm/yyyy'
    | 'mm/dd/yyyy'
    | 'yyyy/mm/dd';

export const formatDateTime = (dateString: string, formatStr: DateFormat): string => {
    const date = moment.utc(dateString);
    const istDate = date.tz('Asia/Kolkata');
    const formatOptions: Record<DateFormat, string> = {
        'dd-MM-yyyy': 'DD-MM-YYYY',
        'MM-dd-yyyy': 'MM-DD-YYYY',
        'yyyy-MM-dd': 'YYYY-MM-DD',
        'dd/mm/yyyy': 'DD/MM/YYYY',
        'mm/dd/yyyy': 'MM/DD/YYYY',
        'yyyy/mm/dd': 'YYYY/MM/DD',
    };
    const formattedDateString = istDate.format(formatOptions[formatStr] || 'DD-MM-YYYY');
    const formattedTime = istDate.format('HH:mm');
    return `${formattedDateString} ${formattedTime}`;
};
