import React, { createContext, useState, ReactNode } from 'react';

// Create the context
export const AccountContext = createContext<{
  accountId: string;
  setAccountId: (id: string) => void;
}>({
  accountId: '',      // Default value
  setAccountId: () => {},  // Empty function as a placeholder
});

// Provider component
export const AccountProvider = ({ children }: { children: ReactNode }) => {
  const [accountId, setAccountId] = useState<string>(''); // Store accountId

  return (
    <AccountContext.Provider value={{ accountId, setAccountId }}>
      {children}
    </AccountContext.Provider>
  );
};
