import axios from 'axios';
import { adminApiService } from "./APIService";
const API_URL = 'http://localhost:60550/api/User/ForgotPassword';

export const sendForgotPasswordEmail = async (email: string): Promise<any> => {
    try {
        const response = await adminApiService.get(`/User/ForgotPassword?emailId=${email}`);
        return response.data;  
    } catch (error: any) {
        if (error.response) {
            console.error("Error Response:", error.response.data);
            throw new Error(`Error: ${error.response.status} - ${error.response.data}`);
        } else if (error.request) {
            console.error("No response received:", error.request);
            throw new Error('No response received from the server');
        } else {
            console.error("Request setup error:", error.message);
            throw new Error('Error sending forgot password email');
        }
    }
};
// export const sendForgotPasswordEmail = async (): Promise<User[] | null> => {
// 	try {
// 		const response = await adminApiService.get<User[]>("/User/GetAllUsersForAllOrganizations");
// 		return response.data;
// 	} catch (error) {
// 		//console.error("Error fetching all users:", error);
// 		throw error;
// 	}
// };
