
import React, { useState, useEffect, ChangeEvent, FocusEvent, FormEvent } from "react";
import {
	TextField,
	Button,
	Select,
	MenuItem,
	FormControl,
	InputLabel,
	FormHelperText,
	SelectChangeEvent,
	Snackbar,
	Alert,
	Box,
	Grid,
	ButtonGroup,
} from "@mui/material";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { getAllUsers, SubmitUserDetails } from "../../services/UserService";
import { adminUrl } from "../../services/APIService";
import { useSnackbar } from "../../SnackbarContext";
import { Fetchuserbyid } from "../../services/UserService";
import { useAuth } from "../auth/AuthProvider";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';


type InputFields = {
	FirstName: string;
	LastName: string;
	EmailId: string;
	Password: string;
	ContactNumber: string;
	Gender: string;
	DateofBirth: string;
	OrganizationId: string;
	UserName: string;
};

type ErrorFields = Partial<InputFields>;

const EditUser = (props: any) => {
	const {
		showEditPopup,
		setShowEditPopup,
		userId,
		fetchUserDataFromApi,
		setModels,
		setLoading,
		fetchUsersList,
		skip,
		top,
		setTotalcount,
		orderByFields,
		filters,
	} = props;
	const { openSnackbar } = useSnackbar();
	const { signOut, userDetails } = useAuth();
	const [OrganizationId, setOrganizationId] = useState(userDetails?.OrganizationId??"");
	const [userDetailss, setUserDetails] = useState({
		FirstName: "",
		LastName: "",
		EmailId: "",
		Password: "",
		ContactNumber: "",
		Gender: "",
		DateofBirth: "",
		OrganizationId: OrganizationId,
		UserName:"",
	});
	const [inputs, setInputs] = useState<InputFields>({
		FirstName: "",
		LastName: "",
		EmailId: "",
		Password: "",
		ContactNumber: "",
		Gender: "",
		DateofBirth: "",
		OrganizationId: OrganizationId,
		UserName:"",
	});

	const [errors, setErrors] = useState<ErrorFields>({
		FirstName: "",
		LastName: "",
		EmailId: "",
		Password: "",
		ContactNumber: "",
		Gender: "",
		DateofBirth: "",
		OrganizationId: "",
	});
	const [isValid, setIsValid] = useState(false);

	const handleTextFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;
		setInputs((prev) => ({ ...prev, [name]: value }));
		let processedValue = value;
		let error = "";
		const numericValue = value.replace(/[^0-9]/g, "");
		if (name === "ContactNumber") {
			const numericValue = value.replace(/\D/g, ""); // Remove all non-numeric characters
			if (numericValue.length !== 10) {
			  error = "Contact Number must be exactly 10 digits.";
			}
			processedValue = numericValue;
			setInputs((prev) => ({ ...prev, [name]: processedValue }));
		  }
		if (name === "FirstName" || name === "LastName") {
			processedValue = value.replace(/[^a-zA-Z]/g, "");
		}
		setInputs((prev) => ({ ...prev, [name]: processedValue }));

		if (name === "FirstName" && processedValue.length < 3) {
			error = "First Name must be at least 3 characters.";
		}

		if (name === "LastName" && processedValue.length < 3) {
			error = "Last Name must be at least 3 characters.";
		}

		if (name === "Password" && value.length > 10) {
			error = "Password cannot exceed 10 characters.";
		}
		if (name === "EmailId") {
			const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
			if (value && !emailRegex.test(value)) {
				error = "Invalid email format.";
			}
			setInputs((prevInputs) => ({
				...prevInputs,
				[name]: value,
			}));
		}
		setIsValid(true);
		setErrors((prev) => ({ ...prev, [name]: error }));
	};
	const [age, setAge] = React.useState("");

	const handleGenderChange = (event: { target: { name: string; value: string } }) => {
		const value = event.target.value;
		setIsValid(true);
		setInputs((prev: any) => ({
			...prev,
			Gender: value,
		}));
	};

	useEffect(() => {
		if (showEditPopup) {
			fetchUserDetails(userId);
		}
	}, [showEditPopup]);

	useEffect(() => {
		const userInfoString = localStorage.getItem("userInfo");	
		if (userInfoString) { 
			try {
				const userInfo = JSON.parse(userInfoString);	
				if (userInfo['user']) {
					const parsedUser = JSON.parse(userInfo['user']);
					if (parsedUser) {
						const OrgId = parsedUser.OrganizationId ?? '';
						setOrganizationId(OrgId);
					}
				}
			} catch (error) {
				console.error("Error parsing userInfo: ", error);
			}
		}
		
	}, []);
	const fetchUserDetails = async (id: any) => {
		try {
			const data = await Fetchuserbyid(id);


			setUserDetails({
				FirstName: data.FirstName,
				LastName: data.LastName,
				EmailId: data.EmailId,
				Password: "**********",
				ContactNumber: data.ContactNumber,
				Gender: data.Gender,
				DateofBirth: data.DateofBirth,
				OrganizationId: OrganizationId,
				UserName:  data.FirstName + " " + data.LastName,
				
			});
			setInputs({
				FirstName: data.FirstName,
				LastName: data.LastName,
				EmailId: data.EmailId,
				Password: "**********",
				ContactNumber: data.ContactNumber,
				Gender: data.Gender,
				DateofBirth: data.DateofBirth,
				OrganizationId: OrganizationId,
				UserName:  data.FirstName + " " + data.LastName,
			});
		} catch (error) {
			console.error("Failed to fetch user details:", error);
		}
	};

	const handleChange = (event: SelectChangeEvent) => {
		setAge(event.target.value);
	};
	const [selectedDate, setSelectedDate] = useState();
	const handleDateChange = (date: any) => {
		setSelectedDate(date);
		setIsValid(true);
		setInputs((prev) => ({ ...prev, DateofBirth: date ? date.format("MM/DD/YYYY") : "" }));
		if (!date) {
			setErrors((prev) => ({ ...prev, DateofBirth: "Date of Birth is required." }));
			setIsValid(false);
		} else {
			setErrors((prev) => ({ ...prev, DateofBirth: "" }));
		}
	};
	const [apierror, setApiError] = useState("");
	const [emails, setEmails] = useState<string[]>([]);
	const [contactNumbers, setContactNumbers] = useState<string[]>([]);
	const [formValid, setFormValid] = useState(false);
	// useEffect(() => {
	// 	const fetchUserDetails = async () => {
	// 	  try {
	// 		const users = await getAllUsers();
	// 		if (users) {
	// 		  const numbers = users.map(user => user.ContactNumber);
	// 		  //const userEmails = users.map(user => user.EmailId);
	// 		  setContactNumbers(numbers);
	// 		  //setEmails(userEmails);
	// 		}
	// 	  } catch (error) {
	// 		console.error('Error fetching user details:', error);
	// 	  }
	// 	};
	
	// 	fetchUserDetails();
	//   }, []);
	const validateInputs = () => {
		const { FirstName, LastName, ContactNumber } = inputs;
		const newErrors: Partial<InputFields> = {};
	
		// First Name Validation
	   
		if (FirstName.length < 3 || FirstName.length > 50) {
		  newErrors.FirstName = 'First Name should contain 3-50 characters';
		}  else if (!/^[A-Za-z]+(?:[ '-][A-Za-z]+)*$/.test(FirstName)) {
		  newErrors.FirstName = 'First Name can only contain alphabets, spaces, apostrophes, and hyphens';
	  }  else {
		  newErrors.FirstName = '';
		}
	
		// Last Name Validation
	  
		 if (LastName.length < 3 || LastName.length > 50) {
		  newErrors.LastName = 'Last Name should contain 3-50 characters';
		}  else if (!/^[A-Za-z]+(?:[ '-][A-Za-z]+)*$/.test(LastName)) {
		  newErrors.LastName = 'Last Name can only contain alphabets, spaces, apostrophes, and hyphens';
	  } else {
		  newErrors.LastName = '';
		}
	
		//Email Validation
		// if (EmailId.length == 0) {
		//   newErrors.EmailId = 'Email is Required';
		// }
		// else if (!/^[a-zA-Z0-9._-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,6}$/.test(EmailId)) {
		//   newErrors.EmailId = 'Email is invalid';
		// // } else if (emails.includes(EmailId)) {
		// //   newErrors.EmailId = 'Email already exists';
		// } else {
		//   newErrors.EmailId = '';
		// }
	
		// Contact Number Validation
		if (!/^\d+$/.test(ContactNumber)) {
		  newErrors.ContactNumber = 'Contact Number should contain only numbers';
		} else if (ContactNumber.length !== 10) {
		  newErrors.ContactNumber = 'Contact Number should be 10 digits';
		} else if (contactNumbers.includes(ContactNumber)) {
		  newErrors.ContactNumber = 'Contact Number already exists';
		} else {
		  newErrors.ContactNumber = '';
		}
	
		// Password Validation
		// if (Password.length < 8 || Password.length > 12) {
		//   newErrors.Password = 'Password must be 8-12 characters long';
		// } else if (!/[A-Z]/.test(Password)) {
		//   newErrors.Password = 'Password must contain one uppercase letter';
		// } else if (!/[a-z]/.test(Password)) {
		//   newErrors.Password = 'Password must contain one lowercase letter';
		// } else if (!/\d/.test(Password)) {
		//   newErrors.Password = 'Password must contain one number';
		// } else if (!/[!@#$%^&*]/.test(Password)) {
		//   newErrors.Password = 'Password must contain one special character';
		// } else {
		//   newErrors.Password = '';
		// }
	
	
		setErrors(newErrors);
		setFormValid(Object.keys(newErrors).every(key => newErrors[key as keyof InputFields] === ''));
		
		return Object.keys(newErrors).every(key => newErrors[key as keyof InputFields] === '');
	  };

	const handleSubmit =  (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		if (!validateInputs()) {
			return;
		}
		try {
				 SubmitUserDetails(
					setLoading,
					setModels,
					setShowEditPopup,
					inputs,
					skip,
					top,
					OrganizationId,
					setTotalcount,
					openSnackbar,
					orderByFields,
					filters,
					setApiError
				
				);
			} catch (error) {

		}

	};

	return (
		showEditPopup && (
			<div className="qadpt-modal-overlay">
			<div className="qadpt-userEditpopup">
				<div className="qadpt-title-sec">
							<div className="qadpt-title">Edit information</div>
							<div className="qadpt-description">Edit the user information</div>
					<svg
						onClick={() => setShowEditPopup(false)}
						className="qadpt-closeicon"
						xmlns="http://www.w3.org/2000/svg"
						x="0px"
						y="0px"
						width="24"
						height="24"
						viewBox="0 0 50 50"
					>
						<path d="M 7.71875 6.28125 L 6.28125 7.71875 L 23.5625 25 L 6.28125 42.28125 L 7.71875 43.71875 L 25 26.4375 L 42.28125 43.71875 L 43.71875 42.28125 L 26.4375 25 L 43.71875 7.71875 L 42.28125 6.28125 L 25 23.5625 Z"></path>
					</svg>
				</div>
					<form
						onSubmit={handleSubmit}
					>
				<Box className="qadpt-formcontent">
					
				<Grid item xs={12} sm={12}>
	<div className="qadpt-usrname">
		<div>
								First Name
								<TextField
									name="FirstName"
									value={inputs.FirstName}
									onChange={handleTextFieldChange}
									helperText={errors.FirstName}
									variant="outlined"
									error={!!errors.FirstName}
									inputProps={{ maxLength: 50 }}
				className="qadpt-userfields"
								/>
		</div>

		<div>
								Last Name
								<TextField
									name="LastName"
									value={inputs.LastName}
									onChange={handleTextFieldChange}
									helperText={errors.LastName}
									variant="outlined"
									error={!!errors.LastName}
									inputProps={{ maxLength: 50 }}
				className="qadpt-userfields"
								/>
		</div>
	</div>
							</Grid>

							<Grid
								item
								xs={12}
								sm={6}
							>
								Email Address
								<TextField
									//required
									name="EmailId"
									value={inputs.EmailId}
									//onChange={handleTextFieldChange}
									//label="Email"
									helperText={errors.EmailId}
									placeholder="example@gmail.com"
									variant="outlined"
									error={!!errors.EmailId}
									inputProps={{ readOnly: true }}
								className="qadpt-userfields"
									// sx={{
									// 	"& .MuiFormHelperText-root": {
									// 		color: errors.EmailId ? "red" : "inherit",
									// 	},
									// 	"& .MuiOutlinedInput-root": {
									// 		padding: "0px",
									// 	},
									// 	"& .MuiInputBase-input": {
									// 		height: "0em",
									// 	},
									// 	width: "430px",
									// 	marginBottom: "-2px",
									// }}
								/>
							</Grid>
							<Grid
								item
								xs={12}
								sm={8}
						>
							Contact Number
							<TextField
							name="ContactNumber"
								value={inputs.ContactNumber}
								onChange={handleTextFieldChange}
							placeholder="Enter 10 digits"
							variant="outlined"
								//helperText={errors.ContactNumber}
								helperText={
									errors.ContactNumber && (
										<>
											<ErrorOutlineIcon fontSize="small" style={{ marginRight: 4 }} />
											{errors.ContactNumber}
										</>
									)
								}
							error={!!errors.ContactNumber}
							required
							inputProps={{
								inputMode: "numeric",
								pattern: "\\d{10}", // Enforce 10 digits
								maxLength: 10, // Maximum of 10 characters
							}}
								className="qadpt-userfields"
									// sx={{
									// 	"& .MuiFormHelperText-root": {
									// 		color: errors.EmailId ? "red" : "inherit",
									// 	},
									// 	"& .MuiOutlinedInput-root": {
									// 		padding: "0px",
									// 	},
									// 	"& .MuiInputBase-input": {
									// 		height: "0em",
									// 	},
									// 	width: "430px",
									// 	marginBottom: "-2px",
									// }}
							/>
							</Grid>
							<Grid
								item
								xs={12}
								sm={6}
							>
								Date of birth
								<LocalizationProvider dateAdapter={AdapterDayjs}>
									<DatePicker
										//label="Date of Birth"
										value={inputs.DateofBirth}
										maxDate={dayjs()}
									onChange={handleDateChange}
									PopperProps={{
										className : "qadpt-datepicker",
									}}
										renderInput={(params) => (
											<TextField
												{...params}
												error={Boolean(errors.DateofBirth)}
												helperText={errors.DateofBirth}
												variant="outlined"
												className="qadpt-userfields"
												// sx={{
												// 	"& .MuiFormHelperText-root": {
												// 		color: errors.DateofBirth ? "red" : "inherit",
												// 	},
												// 	"& .MuiOutlinedInput-root": {
												// 		padding: "0px",
												// 	},
												// 	"& .MuiInputBase-input": {
												// 		height: "0em",
												// 	},
												// 	width: "430px",
												// 	marginBottom: "-2px",
												// }}
											/>
										)}
									/>
								</LocalizationProvider>
							</Grid>
							<Grid
								item
								xs={12}
								sm={8}
								className="qadpt-genderposition"
							>
								Gender
								<FormControl
									variant="standard"
									sx={{ m: 1, minWidth: 420 }}
								>
									<ButtonGroup
										variant="outlined"
										aria-label="Basic button group"
    className="qadpt-genderbuttons"
									>
										<Button
      className={inputs.Gender === "Male" ? "selected" : ''}
											onClick={() =>
												handleGenderChange({
													target: { name: "Gender", value: "Male" },
												})
											}
											variant={inputs.Gender === "Male" ? "contained" : "outlined"}
										>
											Male
										</Button>
										<Button
      className={inputs.Gender === "Female" ? "selected" : ''}
											onClick={() =>
												handleGenderChange({
													target: { name: "Gender", value: "Female" },
												})
											}
											variant={inputs.Gender === "Female" ? "contained" : "outlined"}
										>
											Female
										</Button>
										<Button
      className={inputs.Gender === "Other" ? "selected" : ''}
											onClick={() =>
												handleGenderChange({
													target: { name: "Gender", value: "Other" },
												})
											}
											variant={inputs.Gender === "Other" ? "contained" : "outlined"}
										>
											Prefer not to share
										</Button>
									</ButtonGroup>
								</FormControl>
							</Grid>
						</Box>

						<div className="qadpt-submitbutton">
							

						<Button
								type="submit"
							variant="contained"
						
							>
							SAVE
						</Button>
						</div>
					</form>
				</div>
				</div>
		)
	);
};

export default EditUser;