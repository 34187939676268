import { adminApiService } from "./APIService";

export const ResetPasswordLinkConsumed = async (passwordLogId: string): Promise<any> => {
    try {
        const response = await adminApiService.get(`User/ResetPasswordLinkConsumed?passwordLogId=${passwordLogId}`);
        return response.data;  
    } catch (error: any) {
        if (error.response) {
            console.error("Error Response:", error.response.data);
            throw new Error(`Error: ${error.response.status} - ${error.response.data}`);
        } else if (error.request) {
            console.error("No response received:", error.request);
            throw new Error('No response received from the server');
        } else {
            console.error("Request setup error:", error.message);
            throw new Error('Error sending forgot password email');
        }
    }
};
