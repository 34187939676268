  
  
import React, { useState } from 'react';

import { isSidebarOpen } from "../adminMenu/sidemenustate";
import { Container } from '@mui/material';
import { useTranslation } from 'react-i18next';

const DomainSettings = () => {
  const { t: translate } = useTranslation();
  const [sidebarOpen, setSidebarOpen] = useState(isSidebarOpen());
  return (
    <Container>
      <div className="qadpt-midpart setng-box">
    <div className="qadpt-content-block">
    <div className="qadpt-head">
<div className="qadpt-title-sec">
              <div className="qadpt-title">{translate('Domain Settings')}</div>
  
</div>
<div className="qadpt-right-part">
        
  </div>
<div>
</div>
        </div> 
      
    </div>
</div>
       {/* <div className={`smooth-transition`} style={{marginLeft:sidebarOpen?"170px":""}}>
        <h1 style={{marginLeft:-225}}>Domain Settings</h1>
      </div> */}
   
      </Container>
    );
  };
  

export default DomainSettings