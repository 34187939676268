import { Box, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography, Button, ListItem, List, ListItemText, Rating, IconContainerProps, Divider } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { DeleteOutlined, DownloadOutlined } from '@mui/icons-material';
import TextArea from 'antd/es/input/TextArea';
import ConfirmPopup from './FeedbackConfirmPopup';
import { useSnackbar } from '../../SnackbarContext';
import axios from 'axios';
import { useAuth } from '../auth/AuthProvider';
import { saveFeedback } from '../../services/FeedbackService';
import { useTranslation } from 'react-i18next';
interface ShareFeedbackPopupProps {
  open: boolean;
  onClose: () => void;
  setIsShareFeedbackPopup: any;
}
const StarBox: React.FC<IconContainerProps & { isSelected: boolean }> = ({ value, children, isSelected, ...props }) => (
  <Box
    sx={{
      border: isSelected ? '#dfecec' : '1px solid rgba(34, 34, 34, 0.2)', // Blue border if selected
      borderRadius: '10px',                     // Rounded corners for the box
      paddingLeft: '7px',
      paddingRight: '7px',                      // Space between the star and the box
      marginRight: '15px',                      // Space between the boxes
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      overflow: 'hidden',
      backgroundColor: isSelected ? '#dfecec' : 'transparent', // Blue background if selected
    }}
    {...props}
  >
    {children}
  </Box>
);

const ShareFeedbackPopup: React.FC<ShareFeedbackPopupProps> = ({ open, onClose,setIsShareFeedbackPopup }) => {
  const [rating, setRating] = useState<number | null>(0);
  const { t: translate } = useTranslation();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">("success");
  const [isShareFeedbackPopupTwo, setIsShareFeedbackPopupTwo] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const [confirmationPopup, setConfirmationPopup] = useState(false);
  const [textInput, setTextInput] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [fileError, setFileError] = useState<string>('');
  const { userDetails } = useAuth();
  const { openSnackbar } = useSnackbar();
  const handleSnackbarClose = () => {
		setSnackbarOpen(false);
	};
  const handleClose = () => {
      onClose();
      setFileError("");
      //setRating(null);
      setConfirmationPopup(true);
      
  };

  const handleRatingChange = (event: React.ChangeEvent<unknown>, newValue: number | null) => {
    if (newValue === rating) {
      setRating(null); // Reset rating if same star is clicked again
    } else {
      setRating(newValue);
    }
  };
  const fileToBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };
  
  const handleconfirmclosepopup = () =>
  {
    setConfirmationPopup(false);
    }
    const handleTextInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const value = e.target.value;
        setTextInput(value);
    
        // Trim the value to check for only spaces
        const trimmedValue = value.trim();
    
        // Validation
        if (trimmedValue.length < 5) {
            setError('Text is too short! Minimum length is 5 characters');
        } else if (value.length > 500) {
            setError('Text is too long! Maximum length should be 500 characters');
        } else {
            setError('');
        }
    };
    
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newFiles = Array.from(event.target.files || []);
      if (newFiles.length + files.length > 5) {
        setFileError('You can only upload up to 5 files.');
        return;
      }
  
      const validFiles = newFiles.filter(file => {
        const isValidSize = file.size <= 50 * 1024 * 1024; // 50MB
        const isValidType = ['image/jpeg', 'image/jpg', 'image/png', 'video/mp4'].includes(file.type);
        return isValidSize && isValidType;
      });
  
      if (validFiles.length === 0) {
        setFileError('Please upload files in jpeg, jpg, png, or mp4 formats with a size of up to 50MB.');
        return;
      }
      if (newFiles.length + files.length <= 5 || files.length===0 || newFiles.length + files.length === 0 ) {
        setFileError(''); // Clear the error message if file count is valid
      }
      setFiles(prevFiles => [...prevFiles, ...validFiles]);
    };
  

  const handleFileDelete = (fileName: string) => {
    setFiles(prevFiles => prevFiles.filter(file => file.name !== fileName));
  };

  const handleFileDownload = (file: File) => {
    const url = URL.createObjectURL(file);
    const a = document.createElement('a');
    a.href = url;
    a.download = file.name;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };


  useEffect(() => {
    if (isShareFeedbackPopupTwo) {
      setRating(null); // Reset rating when popup is closed
    }
  }, [ShareFeedbackPopup]);
  const handleFeedbackPopup = async () => {
    // Validation logic
    if (textInput.length < 5) {
      setError('Text is too short! Minimum length is 5 characters');
      return;
    }
    if (textInput.length > 50) {
      setError('Text is too long! Maximum length is 50 characters');
      return;
    }
  
    try {
      // Convert files to Base64 strings
      const base64Files = await Promise.all(files.map(file => fileToBase64(file)));
      // Convert base64Files array to a comma-separated string
      const base64FilesString = base64Files.join(',');
      
      const userId = userDetails?.UserId;
      const orgId = userDetails?.OrganizationId;
  
      // Prepare feedback data
      const feedbackData = {
        UserId: userId || '',
        OrganizationId: orgId || '',
        Message: textInput,
        FeedbackFile: base64FilesString || "",
        Rating: rating || '0',
        CreatedDate: new Date().toISOString(),
      };

  
      // Make API call with correct headers
        const responseData = await saveFeedback(feedbackData);
      if (responseData.Success) {
        setTextInput('');
        setFiles([]);
        onClose();
          setIsShareFeedbackPopupTwo(true);
          setRating(null);
      }
    } catch (error) {
      setError('An error occurred while submitting feedback. Please try again.');
      
    }
  };
  
 
  
  
  

  const handlecloseShareFeedback = () => {
    setIsShareFeedbackPopupTwo(false);
  };
  const isSubmitDisabled = textInput.length < 5 || !!error;
  return (
    <>
      <Dialog
        open={open && !isShareFeedbackPopupTwo}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleClose(); // Only close when the close button is clicked
          }
        }}
        PaperProps={{
          className: 'qadpt-feedbackpopup'
        }}
       
        disableEscapeKeyDown
      >
   <div className="qadpt-feedback-header">
  <div className="qadpt-feedback-title">
            <div>{translate('Share Feedback')} </div>
            <div className="qadpt-feedback-subtitle">
              {translate('Tell us what you think about our product')}
  </div>
  </div>
  <IconButton className="qadpt-close-icon" onClick={handleClose}>
    <CloseIcon />
  </IconButton>
  
</div>
<Divider></Divider>
        <div className='qadpt-content'>
          <div className='qadpt-container'>
          <span  className='qadpt-label'>
              {translate('What would you rate this product')}
          </span>

          <Box className="qadpt-rating">
  <Rating
    name="product-rating"
    value={rating}
    onChange={handleRatingChange}
    IconContainerComponent={(props) => (
      <StarBox {...props} isSelected={rating !== null && props.value <= (rating || 0)} />
    )}
    className="qadpt-rating-stars"
  />
</Box>

          </div>
          <div className='qadpt-container'>
          <span  className='qadpt-label'>
              {translate('Your Message')}
            </span>

          <div className='qadpt-textarea'>
            <TextArea
               value={textInput}
               onChange={handleTextInputChange}
                placeholder={translate('How would you like our application')}
              maxLength={500}
            />
             {error && (
            <Typography sx={{ color: 'red', fontSize: '12px', marginTop: '2px', marginLeft: '25px' }}>
              {error}
            </Typography>
          )}
            </div>
                                                        
  <div className="character-count" style={{marginLeft:248}}>
                    Count: {textInput.length}/500
                </div>
            </div>
            <div className="qadpt-upload-container">
  <div>
    <Button
      className="qadpt-upload-button"
      variant="contained"
      component="label" // This makes the Button act as a label for the input
      startIcon={<CloudUploadOutlinedIcon />}
    >
                {translate('Upload File')}
      <input
        type="file"
        multiple
        accept=".jpeg, .jpg, .png, .mp4"
        onChange={handleFileChange}
        style={{ display: 'none' }} // Keep input hidden
      />
    </Button>
  </div>
  <span className="qadpt-upload-description">
              {translate('5 total jpeg, png & mp4 format files up to 50MB')}
  </span>
</div>

          

          
<div>
  <Box className="qadpt-file-list">
    <List>
      {files.map((file, index) => (
        <ListItem key={file.name} className="qadpt-list-item">
          <Typography className="qadpt-file-name">{file.name}</Typography>
          <Box className="qadpt-file-actions">
            <IconButton
              onClick={() => handleFileDownload(file)}
            >
              <DownloadOutlined />
            </IconButton>
            <IconButton
              onClick={() => handleFileDelete(file.name)}
            >
              <DeleteOutlined />
            </IconButton>
          </Box>
        </ListItem>
      ))}
    </List>
    {fileError && (
      <Typography className="qadpt-fileerror">{fileError}</Typography>
    )}
  </Box>
</div>

</div>

<div className="qadpt-footer">
  <Button
    className={`qadpt-submit-button ${isSubmitDisabled ? 'qadpt-submit-disabled' : ''}`}
    type="submit"
    onClick={handleFeedbackPopup}
    disabled={isSubmitDisabled}
  >
            {translate('Submit')}
  </Button>
</div>

        <DialogActions sx={{ padding: '0px' }}>
          {/* Add any actions if needed */}
        </DialogActions>
      </Dialog>

   {isShareFeedbackPopupTwo && (
  <Dialog
    open={isShareFeedbackPopupTwo}
    onClose={handlecloseShareFeedback}
    PaperProps={{ className: 'qadpt-feedback-dialog' }}>
    <div className='qadpt-dialogcontent'>
    <DialogTitle>
      <div className="qadpt-message">
                {translate('Thanks For Your Feedback')}
      </div>
    </DialogTitle>
    <DialogActions>
      <div className='qadpt-actionbutton'>
        <Button onClick={handlecloseShareFeedback}>
                  {translate('Close')}
        </Button>
      </div>
            </DialogActions>
      </div> 
  </Dialog>
)}


{confirmationPopup ? (
				<ConfirmPopup
					onClose={() => setConfirmationPopup(false)}
					onOk={() => {
            setConfirmationPopup(false);
            setIsShareFeedbackPopup(true);
          }}
          setTextInput={setTextInput}
          setFiles={setFiles}
          setError={setError}
          setFileError={setFileError}
          title={translate('Confirmation')}
          description={translate('You will lose any added')}
          descriptionTwo={translate('information by closing')}
					button1={translate('Cancel')}
					button2={translate('Go back')}
				/>
			) : null}

    </>
  );
};

export default ShareFeedbackPopup;
