// src/oidcConfig.ts
import { UserManagerSettings, WebStorageStateStore } from "oidc-client-ts";
 
const oidcConfig: UserManagerSettings = {
    authority: "https://localhost:44301", //"https://app.quickadopt.in/", //https://localhost:44301/
    client_id: "react_dap",
    redirect_uri: "http://localhost:3000/callback", // 'https://web.quickadopt.in/callback', //http://localhost:3000/callback
    post_logout_redirect_uri: "http://localhost:3000", //'https://web.quickadopt.in',   //http://localhost:3000
    response_type: "code",
    scope: "openid profile api1",
    automaticSilentRenew: true,
    loadUserInfo: true,
    userStore: new WebStorageStateStore({ store: window.localStorage }),
};
 
export default oidcConfig;
